import { AfterLogin } from "../centralverification-initializer-legacy.js";
import { AError } from "../classes/AError.js";
import { AForm } from "../core/form/AForm.js";
import { ALERT_TITLES } from "../services/AAlertService.js";
import { EVENTS } from "../services/AEventService.js";
export class APage {
    constructor() {
        const { UseUsername, HidePassword, ConcealPassword, UseAuthenticator } = Config;
        globalThis.baseTitle = document.title;
        this.setupUsername({ UseUsername })
            .setupPassword({ HidePassword, ConcealPassword })
            .setupAuthenticator({ UseAuthenticator })
            .setupLogin()
            .show();
        Events.on(EVENTS.VERIFICATION_CHANNEL, async (channels) => {
            const state = await channelService.showVerificationChannels(channels, { autoProceed: true });
            if (state !== false) {
                if (stateService.isLoggedIn({ Status: state })) {
                    await this.loginSucceeded();
                }
            }
        });
    }
    setupUsername({ UseUsername }) {
        if (UseUsername == true) {
            $('#UsernameField').attr('style', '');
            $('#Username').on('keypress', (e) => {
                if (e.which == 13) {
                    $('#Password').trigger('focus');
                }
            });
        }
        return this;
    }
    setupPassword({ UseAuthenticator, HidePassword, ConcealPassword }) {
        $('#PasswordField').attr('style', '');
        const hidePassword = (HidePassword == true || ConcealPassword == true);
        const inputType = (hidePassword) ? 'password' : 'text';
        $('#Password').attr('type', inputType);
        $("#Password").on('keypress', (e) => {
            if (e.which == 13) {
                if (UseAuthenticator == true) {
                    $("#AuthenticatorCode").trigger('focus');
                }
                else {
                    $("#Login").trigger("click");
                }
            }
        });
        return this;
    }
    setupAuthenticator({ UseAuthenticator }) {
        $("#AuthenticatorCode").on('keypress', function (e) {
            if (e.which == 13) {
                $("#Login").trigger("click");
            }
        });
        $('#AuthenticatorCodeHolder').css({
            display: UseAuthenticator === true ? 'inline-block' : 'none'
        });
        return this;
    }
    setupLogin() {
        $('#Login').on('click', e => Loading.waitForPromises(this.attemptLogin()));
        return this;
    }
    async attemptLogin() {
        $('#login-form').addClass('loader-active');
        $('#login-form :input').prop('disabled', true);
        const creds = AForm.extractFormData($('#login-form'), { ignoreWildcards: true });
        const loginResponse = await requestService.send('LoginRequest', {
            SessionMode: 'FollowUp',
            // GroupId: creds.EnforeUnitId,
            // ListName: creds.LinkableDevices ?? '',
            ...creds,
        }, { priority: 5, waitForEvent: EVENTS.STATE_STREAM });
        if (loginResponse.Status.indexOf('Invalid') === -1) {
            _.setUser(loginResponse);
            if (stateService.isLoggedIn(loginResponse)) {
                if (loginResponse.Status !== 'WaitingForVerificationChannel') {
                    await this.loginSucceeded();
                }
            }
        }
        else {
            AError.handleSilent(`Didn't expect Status="${loginResponse.Status}"`);
            Alerts.show({
                title: ALERT_TITLES.Warning,
                content: loginResponse.StatusString
            });
        }
        $('#login-form :input').prop('disabled', false);
        $('#login-form').removeClass('loader-active');
    }
    async loginSucceeded() {
        try {
            await Loading.waitForPromises(AfterLogin());
            routeService.startListening();
            this.allowHeaderHover();
        }
        catch (err) {
            AError.handle(err);
        }
    }
    allowHeaderHover() {
        $('.top-logo').attr('atooltip', [
            `ACI Server ${Config?.ServerBuildInfo}`,
            `ACI Client ${Config?.ClientBuildInfo.text}`,
            `Customer #${Settings?.NodeCustomerNumber}`,
        ].join('<br />'));
    }
    show() {
        $('#label1').text('Central Verification');
        $('#label2').text('SCANaCAR');
        $('#login-form').addClass('animated');
        $('#login-form :input').eq(0).trigger('focus');
        return this;
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .form-select-container .form-select.form-focus {
        flex: 0 1 33.5%;
        padding: .2rem;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="first-load">
      <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem;"></div>
    </div>
    <div class="container">
      <div class="columns">
        <form id="login-form" class="column col-3 col-md-8 col-sm-10 col-xs-12 col-mx-auto has-loader">
          <div class="loader-container">
            <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem;"></div>
          </div>
    
          <div class="text-center loader-show" style="padding-bottom: 10px">
            <span id="label2" class="text-logo text-sm"
              style="text-transform: none; -webkit-background-clip: text;"></span><br>
            <span id="label1" class="text-logo" style="-webkit-background-clip: text;"></span><br>
          </div>
    
          <div class="divider text-center loader-show" data-content=""></div>
    
          <div class="col-12 form-group">
            <label class="form-label" for="Username">Username</label>
            <input class="form-input" type="text" autocomplete="name" id="Username" name="Username" placeholder="Username">
          </div>
          <div class="col-12 form-group">
            <label class="form-label" for="Password">Password</label>
            <input class="form-input" type="password" autocomplete="current-password" id="Password" name="Password" placeholder="Password">
          </div>
          <div class="col-12 form-group" id="AuthenticatorCodeHolder">
            <label class="form-label" for="AuthenticatorCode">Authenticator Code</label>
            <input class="form-input" type="text" id="AuthenticatorCode" name="AuthenticatorCode" placeholder="AuthenticatorCode">
          </div>
    
          <div class="divider text-center loader-show" data-content=""></div>
    
          <div class="columns loader-show">
            <div class="column col-12">
              <button class="btn btn-primary btn-shadow btn-glossy col-12" id="Login">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                Sign In
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
    
        </form>
      </div>
    </div>
  `);
}
