import { AEngine } from "../core/AEngine.js";
import { AEventableValue } from "../core/eventable/AEventableValue.js";
import { ALERT_TITLES, ALERT_BUTTONS } from "./AAlertService.js";
import { EVENTS } from "./AEventService.js";
export class ACentralVerificationService {
    constructor() {
        this.queue = [];
        this.verdicts = new AEventableValue({ initialValue: [] });
        this.verdictObservable = new AEventableValue({});
        if (window.cvs === undefined) {
            Object.assign(globalThis, {
                cvs: this
            });
        }
    }
    autoInit() {
        Events.hardwire('VerifyRequest', (detection, b) => {
            this.queue.push(detection);
            Events.tryInvoke('VerifyRequestPrepared', detection);
        });
    }
    listen(cbFn) {
        Events.on('VerifyRequestPrepared', cbFn);
    }
    sendVerdict(verdict, context) {
        const verdictWithContext = { ...verdict, ...context };
        // this.setPreviousDetection(verdictWithContext, context)
        this.verdicts.value.push(verdictWithContext);
        this.verdictObservable.add(verdictWithContext);
        this.verdicts.reload();
        CCCClient.SendMessage('VerifyResponse', 1, verdict);
    }
    async detectionChangeRegimeCode(selected, params) {
        const promise = Loading.waitForEvent('ChangeRegimeResponse');
        CCCClient.SendMessage("ChangeRegimeRequest", 1, {
            DetectionId: selected.DetectionId,
            DetectionDeviceId: selected.DetectionDeviceId,
            RegimeCode: params.RegimeCode
        });
        // const cfgRegimes: {[OffenceCode: string]: } = Config.Regimes
        const newData = await promise;
        if (newData.MustFollowUp === true) {
            cvs.queue.unshift(Object.assign({}, selected, newData));
        }
        else {
            Alerts.show({
                title: ALERT_TITLES.Success,
                buttons: ALERT_BUTTONS.ok,
                content: await Translate.get('The detection has been moved to another channel!'),
            });
        }
    }
    async detectionChangeOffenceCode(selected, params) {
        const promise = Loading.waitForEvent('ChangeOffenceResponse');
        CCCClient.SendMessage("ChangeOffenceRequest", 1, {
            DetectionId: selected.DetectionId,
            DetectionDeviceId: selected.DetectionDeviceId,
            OffenceCode: params.OffenceCode
        });
        const newData = await promise;
        if (newData.MustFollowUp === true) {
            cvs.queue.unshift(Object.assign({}, selected, newData));
        }
        else {
            Alerts.show({
                title: ALERT_TITLES.Success,
                buttons: ALERT_BUTTONS.ok,
                content: await Translate.get('The detection has been moved to another channel!'),
            });
        }
    }
    async detectionPermitRecheck(selected) {
        const updatedDetectionPromise = Loading.waitForEvent(EVENTS.PARKING_RIGHT_STREAM);
        CCCClient.SendMessage("ParkingRightRequest", 1, {
            DetectionId: selected.DetectionId,
            DetectionDeviceId: selected.DetectionDeviceId
        });
        const newData = await Loading.waitForPromises(updatedDetectionPromise);
        const updatedDetection = Object.assign({}, selected, newData);
        // const events = Alerts.show({
        //   title: ALERT_TITLES.Success,
        //   buttons: ALERT_BUTTONS.yesNo,
        //   content: await Loading.waitForPromises(
        //     Translate.get(`Detection Does ${updatedDetection.MustFollowUp ? 'Not ' : ''}Have Parking Right, Would you like to skip this detection and proceed to the next one?`)
        //   )
        // })
        // return await new Promise<void>((resolve) => {
        //   events.on(ALERT_STATUS.ON_MODAL_CLOSED, (opt) => {
        //     if (opt.action !== ALERT_STATUS.ON_ACTION_PROCEED) {
        //       cvs.queue.unshift(updatedDetection)
        //     }
        //     resolve()
        //   })
        // })
        if (updatedDetection.MustFollowUp === true) {
            cvs.queue.unshift(updatedDetection);
        }
        else {
            Alerts.show({
                title: ALERT_TITLES.Success,
                buttons: ALERT_BUTTONS.ok,
                content: await Translate.get('According to the database the detection does have parking rights'),
            });
        }
    }
    async detectionChangeRequest(selected, params) {
        const updatedDetectionPromise = Loading.waitForEvent(EVENTS.PARKING_RIGHT_STREAM);
        CCCClient.SendMessage("DetectionChangeRequest", 1, params);
        const newData = await updatedDetectionPromise;
        const updatedDetection = Object.assign({}, selected, newData);
        if (AEngine.isDevelopmentMode) {
            AEngine.log('data-old', selected);
            AEngine.log('data-new', newData);
        }
        // Temporary fix talk to jaap
        Object.assign(updatedDetection, { CountryCode: params.NewValues.CountryCode });
        // If detection has parking right then no verification is needed
        if (updatedDetection.MustFollowUp === true) {
            cvs.queue.unshift(updatedDetection);
        }
        else {
            Alerts.show({
                title: ALERT_TITLES.Success,
                buttons: ALERT_BUTTONS.ok,
                content: await Translate.get('No further action is needed for this detection!'),
            });
        }
    }
    // This cancels fine & sends it to the cancellation channel
    // The teamleader will process this detection using VerificationResult & VerificationResultText
    cancelFine(data) {
        // "Type":"CancelFine",
        // "Data":{ "DetectionId":"36497151358664705", "DetectionDeviceId":"281629608771585", "Reason":"He bought me flowers" }
        requestService.send("CancelFine", data);
        // this.resetPreviousDetection()
    }
}
