import { AError } from "../../classes/AError.js";
import { AImageHelper } from "../../classes/AImageHelper.js";
import { APhotoFetcher } from "../../classes/APhotoFetcher.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { AIsLatLngValid, createMap } from "../../utils/maps.js";
import { ADurationToSeconds, AFormatDate, ARound, AShowTable, AUrlEncodedImageFromBase64, formatPrice, transformButtonCls, transformTextCls, waitForChromeFrame } from "../../utils/tools.js";
import { AMeterService } from "../../services/AMeterService.js";
import { AColor } from "../../classes/AColor.js";
import { AWindowRelay } from "../../classes/AWindowRelay.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { EVENTS } from "../../services/AEventService.js";
import { COLUMN_BOOLEAN_NULLABLE, COLUMN_HIDDEN } from "../../classes/AGridTypes.js";
import { LICENSE_PLATE_COUNTRY_CODES } from "../../core/country_codes/country_codes.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ATableFormatter } from "../../classes/ATableFormatter.js";
import { APurgatoryService } from "../../services/APurgatoryService.js";
import { escapeHtml } from "../../utils/json.js";
import { AGeoUtils } from "../../core/maps/AGeoUtils.js";
import { distanceGoogleMaps } from "../../utils/geo_tools.js";
var APageSteps;
(function (APageSteps) {
    APageSteps[APageSteps["Initial"] = 0] = "Initial";
    APageSteps[APageSteps["LicensePlate"] = 1] = "LicensePlate";
    APageSteps[APageSteps["Map"] = 2] = "Map";
    APageSteps[APageSteps["Remarks"] = 3] = "Remarks";
    APageSteps[APageSteps["Verdict"] = 4] = "Verdict";
})(APageSteps || (APageSteps = {}));
// function fetchStreetData(coordinates: google.maps.LatLng|google.maps.LatLngLiteral): Promise<any> {
//   return new Promise((resolve, reject) => {
//     const geocoder = new google.maps.Geocoder()
//     geocoder.geocode({ 'location': coordinates }, function (results, status) {
//       if (status !== 'OK') {
//         return reject(status)
//       }
//       return resolve(results)
//     })
//   })
// }
// async function findAddressTemp(coords: ALatLng|ALatLngLiteral): Promise<AAddressData|undefined> {
//   try {
//     const dataList = await fetchStreetData(coords)
//     const data = dataList[0]
//     const houseNum = (data.address_components as any[]).find(v => v.types.includes('street_number'))?.long_name
//     const street = (data.address_components as any[]).find(v => v.types.includes('route'))?.long_name
//     const city = (data.address_components as any[]).find(v => v.types.includes('locality'))?.long_name
//     const municipality = (data.address_components as any[]).find(v => v.types.includes('administrative_area_level_2'))?.long_name
//     const province = (data.address_components as any[]).find(v => v.types.includes('administrative_area_level_1'))?.long_name
//     const postalCode = (data.address_components as any[]).find(v => v.types.includes('postal_code'))?.long_name
//     return {
//       Municipality: municipality,
//       City: city,
//       Street: street + ' ' + houseNum,
//       PostalCode: postalCode,
//       Zone: '?'
//     }
//   } catch (err) {
//     AError.handle({
//       err: err,
//       useModal: false,
//       useCentralServerLogging: false,
//       adminAlertGroup: 'FETCH_ADDRESS_GOOGLE'
//     })
//   }
// }
export class APage {
    get linkedDetection() { return (this.DISABLE_LINKED) ? undefined : this.selected?.LinkedDetections[0] || undefined; }
    constructor() {
        this.DISABLE_LINKED = false;
        this.showOffenceCodes = true;
        this.step = APageSteps.Initial;
        this.SessionMarkers = {};
        this.imgIndex = 0;
        this.showOneStepConcurrently = false;
        this.autoVerdictEnabled = false;
        this.verdictRoute = [];
        this.map = createMap('map', { zoom: 17 });
        const defaultCoords = geoService.defaultCoordinates;
        this.map.panToBounds(defaultCoords.bounds.toJSON());
        this.map.setCenter(defaultCoords.center.toJSON());
        this.imageHelper = new AImageHelper({
            $photos: $('#photos-primary'),
            $rh: $('#photos-primary'),
            $lpImage: $('.license-plate')
        });
        this.linkedImageHelper = new AImageHelper({
            $photos: $('#photos-secondary'),
            $rh: $('#photos-secondary'),
            // $lpImage: $('.license-plate')
        });
        this.photoFetcher = new APhotoFetcher();
        $('#split-view').on('click', (e) => this.splitView());
        $('#prdb-recheck').on('click', (e) => this.recheckPrdb());
        $('#history').on('click', (e) => {
            Loading.waitForPromises(this.showHistoryModal(this.selected)).catch(AError.handle);
        });
        this.initDetectionInputs();
        this.initKpiCollapse();
        this.bindShortcuts(document);
    }
    async init() {
        const t = await Translate.get([
            'cancel',
            'canceled',
            'go back',
            'Country Code could not be found!',
            'DetectionId',
            'DetectionDeviceId',
            'Timestamp',
            'ColorCls',
            'Result',
            'LicensePlate',
            'Verdict',
            'Cancel Fine',
            'Cancel Reason',
            'Please enter a reason',
            `Press "Enter" To Continue`,
            `name`,
            'distance',
            `outOfOrder`,
            `status`,
            'default'
        ]);
        this.t = t;
        // TODO: Remove focus on markers button
        await coreMapService.prepareMapItems(MAP_OPTIONS.Default, {
            createToggleItems: true,
        }).catch(AError.handle);
        this.verdictHistoryTable = AShowTable({
            appendTo: 'verdict-history-table',
            aci: {
                flex: 1,
                resizeToFit: false,
            },
            features: {
                sort: false,
                filter: false,
                columnResize: false,
            },
            columns: [
                { text: this.t['DetectionId'], field: 'DetectionId', ...COLUMN_HIDDEN },
                { text: this.t['DetectionDeviceId'], field: 'DetectionDeviceId', ...COLUMN_HIDDEN },
                { text: this.t['Timestamp'], field: 'timestamp', ...COLUMN_HIDDEN },
                { text: this.t['ColorCls'], field: 'ColorCls', ...COLUMN_HIDDEN },
                { text: this.t['Canceled'], field: 'Canceled', ...COLUMN_HIDDEN },
                { text: this.t['Result'], field: 'Result', ...COLUMN_HIDDEN },
                { text: this.t['LicensePlate'], field: 'LicensePlate' },
                {
                    flex: 1,
                    text: this.t['Verdict'],
                    field: 'ResultText',
                    renderer: ({ cellElement, record, value }) => {
                        const labelCls = [transformTextCls(record.ColorCls)];
                        // const cell = (cellElement as HTMLElement)
                        // cell.classList.add(...labelCls)
                        return ( /*html*/`
              <span style="font-weight: 400; ${record.Canceled ? `text-decoration: line-through;` : ''}" class="${labelCls.join(' ')}">${value}</span>
            `);
                    },
                    htmlEncode: false
                },
                {
                    flex: 1,
                    text: '',
                    field: 'Action',
                    renderer: ({ record, value }) => {
                        if (!record.AllowCancel)
                            return '';
                        return record.Canceled ? ( /*html*/`
              <button disabled="disabled" class="btn btn-grey full-width">
                <i class="fa-solid fa-check"></i>
                ${t['canceled']}
              </button>
            `) : ( /*html*/`
              <button class="btn btn-error full-width">
                <i class="fa-solid fa-xmark"></i>
                ${t['cancel']}
              </button>
            `);
                    },
                    htmlEncode: false
                }
            ],
            data: cvs.verdicts.value.reverse(),
        });
        const COLUMN_DISTANCE = {
            htmlEncode: false,
            renderer: ({ value }) => (value ? ARound(value, 2).toFixed(2) + ' m' : '')
        };
        this.additionalDataTable = AShowTable({
            appendTo: 'custom-data-table',
            aci: {
                flex: 1,
                resizeToFit: false,
            },
            features: {
                sort: false,
                filter: false,
                columnResize: false,
            },
            columns: [
                { text: 'Attr', field: 'Attr' },
                { text: 'Value', field: 'Value' },
            ],
            data: [],
        });
        this.parkingMachinesTable = AShowTable({
            appendTo: 'parking-machines-table',
            aci: {
                flex: 1,
                resizeToFit: false,
            },
            features: {
                sort: false,
                filter: false,
                columnResize: false,
            },
            columns: [
                { text: this.t['name'], field: 'displayName' },
                { text: this.t['distance'], field: 'distance', ...COLUMN_DISTANCE },
                { text: this.t['status'], field: 'inOrder', ...COLUMN_BOOLEAN_NULLABLE },
                { text: 'remainingTickets', field: 'remainingTickets', ...COLUMN_HIDDEN },
                { text: 'interventionRequest', field: 'interventionRequest', ...COLUMN_HIDDEN },
                { text: 'providerId', field: 'providerId', ...COLUMN_HIDDEN },
                { text: 'urbanParkingSiteId', field: 'urbanParkingSiteId', ...COLUMN_HIDDEN },
                { text: 'airBatteryLevel', field: 'airBatteryLevel', ...COLUMN_HIDDEN },
                { text: 'leadAcidBatteryLevel', field: 'leadAcidBatteryLevel', ...COLUMN_HIDDEN },
                { text: 'lastEventDate', field: 'lastEventDate', ...COLUMN_HIDDEN },
            ],
            data: [],
        });
        this.parkingMachinesTable.on('cellclick', async (event) => {
            const { record, column } = event;
            const { lat, lng } = record;
            this.map.setCenter({ lat, lng });
        });
        this.verdictHistoryTable.on('cellclick', async (event) => {
            const { record, column } = event;
            const { DetectionId, DetectionDeviceId } = record;
            const secSinceVerdict = (Date.now() - record.Timestamp) / 1000.0;
            if (secSinceVerdict >= this.fineCancelationMaxWaitDuration) {
                Alerts.show({
                    title: ALERT_TITLES.Error,
                    content: await Loading.waitForPromises(Translate.get(`The cancellation period of ${this.fineCancelationMaxWaitDuration} seconds has been surpassed, you're not allowed to cancel this verdict anymore!`)),
                    buttons: ALERT_BUTTONS.ok
                });
                return;
            }
            if (record.AllowCancel && !record.Canceled && column.data.field === 'Action') {
                const m = Alerts.show({
                    translatedTitle: this.t['Cancel Fine'],
                    buttons: ALERT_BUTTONS.okCancel,
                    content: ( /*html*/`
            <div class="form-group">
              <label class="form-label label-sm" for="input-reason">${this.t['Cancel Reason']}</label>
              <textarea id="input-reason" class="form-input" placeholder="..." autocomplete="off"></textarea>
              <p class="form-input-hint show-if-has-error">${this.t['Please enter a reason']}</p>
            </div>
          `)
                });
                m.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
                    const $reason = m.$ele.find('.form-group #input-reason');
                    const Reason = ($reason.val() || '');
                    const hasError = Reason === '' || Reason.length === 0;
                    $reason.closest('.form-group').toggleClass('has-error', hasError);
                    $reason.val('');
                    if (hasError) {
                        return false;
                    }
                    this.cancelFineClick({ DetectionId, DetectionDeviceId, Reason }, record);
                });
            }
        });
        Events.on(EVENTS.CHANNEL_QUEUES_UPDATED, (stats) => {
            this.updateQueues(stats);
        });
        Events.on("ServerStatsStream", (data) => {
            console.log('ServerStatsStream', data);
            const { BackofficeVerificationQueue, Waiting, WaitingPdaCount, WorkingPdaCount } = data;
            const $stats = $('#stats');
            // $stats.find('#localqueue span').text()
            $stats.find('#queue span').text(BackofficeVerificationQueue);
            $stats.find('#cars span').text(this.scanVehicleCount);
            $stats.find('#pdaqueue span').text(Waiting);
            $stats.find('#waiting span').text(WaitingPdaCount);
            $stats.find('#working span').text(WorkingPdaCount);
        });
        Events.on(EVENTS.DESTRUCT, () => {
            if (this.selected) {
                cvs.queue.unshift(this.selected);
            }
        });
        mapHelperService.displaySessionsOnMap({
            interpolate: true,
            sessions: this.SessionMarkers
        });
        // this.splitParkingSpaces = await Loading.waitForPromises(
        //   AEngine.get(AParkingSpaceService).loadSplitParkingSpaces({ parseToMap: true })
        // )
        this.parkingSpaces = await Loading.waitForPromises(geoService.load(MAP_OPTIONS.ParkingSpace, { parseToMap: true, addClickListener: true }));
        this.parkingMachines = await Loading.waitForPromises(geoService.load(MAP_OPTIONS.ParkingMachine, { parseToMap: true, addClickListener: true }));
        if (this.parkingMachines.length > 0) {
            $('#parking-machines').removeClass('hidden');
        }
        // await mapHelperService.prepareMapItems(0, {
        //   showLegend: false,
        //   allowExport: false,
        // })
        $("#lp-form").on("submit", (e) => e.preventDefault());
        $("#verdict-form").on("submit", (e) => e.preventDefault());
        // TODO: Uncomment below
        // await mapHelperService.showOnMap(MAP_OPTIONS.ParkingSpaces)
        this.redrawVerdictForm().catch(AError.handle);
        this.selectNextInQueue().catch(AError.handle);
        this.listenToEvents();
    }
    get imageHelpers() {
        const all = [this.imageHelper, this.imageHelperProxy];
        return all.filter(v => v !== undefined);
    }
    get linkedImageHelpers() {
        const all = [this.linkedImageHelper, this.linkedImageHelperProxy];
        return all.filter(v => v !== undefined);
    }
    get scanVehicleCount() {
        const types = ['ScanAuto', 'ScanScooter', 'ScanSegway', 'ScanBike', 'ScanCam'];
        let count = 0;
        for (let node of Sessions) {
            if (types.includes(node.NodeType) && node.Status !== 'Disconnected') {
                count += 1;
            }
        }
        return count;
    }
    get isTimeLimitedParking() {
        return (this.selected !== undefined && this.linkedDetection !== undefined);
    }
    get isEnforcing() {
        return stateService.state.Status === 'ReadyToFollowUp';
    }
    get hasDetections() {
        return cvs.queue.length > 0 || this.selected !== undefined;
    }
    get fineCancelationMaxWaitDuration() {
        if (typeof Config.CancelFineMaxTime !== 'string') {
            AError.handleSilent(`Config.CancelFineMaxTime is not correctly set! value=${Config.CancelFineMaxTime}`);
            return ADurationToSeconds('1 Minute');
        }
        return ADurationToSeconds(Config?.CancelFineMaxTime || '1 Minute');
    }
    async showHistoryModal(detection) {
        const deepCopy = JSON.parse(JSON.stringify(detection));
        try {
            delete deepCopy.LPImage;
            delete deepCopy.OverviewImages;
            delete deepCopy.CarImage;
        }
        catch (err) {
            console.error(err);
        }
        const TableBuilder = await AEngine.get(APurgatoryService).createSingleTable({
            greyOutFields: true,
            data: deepCopy,
            tableFormatter: new ATableFormatter({
                "Latitude": { type: 'TEXT' },
                "Longitude": { type: 'TEXT' },
                "Success": { type: 'BOOLEAN' },
                "GracePeriod": { type: 'TEXT' },
                "MustFollowUp": { type: 'BOOLEAN' },
                "OffenceCode": { type: 'TEXT' },
                "OffenceText": { type: 'TEXT' },
                "OffenceDescription": { type: 'TEXT' },
                "OffencePrice": { type: 'TEXT' },
                "VerificationDisplayText": { type: 'TEXT' },
                "Location": { type: 'OBJECT' },
                "VerificationExpireTime": { type: 'DATETIME' },
                "Address": { type: 'HIDDEN' },
                "LinkedDetections": { type: 'HIDDEN' },
                "GeoMatches": { type: 'HIDDEN' },
            })
        });
        const modal = Alerts.show({
            translatedTitle: detection.LicensePlate || '',
            type: ALERTS.Large,
            buttons: ALERT_BUTTONS.ok,
            content: ( /*html*/`
        <div class="columns">
          <div class="column col-12">
            ${TableBuilder.build({
                visible: true,
                sorting: [
                    "DetectionId",
                    "DetectionDeviceId",
                    "LicensePlate",
                    "CountryCode",
                    "OffenceCode",
                    "OffenceText",
                    "OffenceDescription",
                    "OffencePrice",
                    "DetectionTime",
                    "ParkingRightCheckTime",
                    "VerificationDisplayText",
                    "VerificationExpireTime",
                    "GracePeriod",
                    "ParkingRightType",
                    "IsIllegallyParked",
                    "HasParkingRight",
                    "MustFollowUp",
                    "ParkingAreaType",
                    "ParkingRightResults",
                    "Location",
                    "DetectionStreet",
                    "PostalCode",
                    "ClosestAddress",
                    "Latitude",
                    "Longitude",
                    "ScanDeviceLatitude",
                    "ScanDeviceLongitude",
                    "Success",
                ]
            })}
            <!-- <div id="grid-log" class="full-height" style="height: ${Math.round(window.innerHeight / 3 * 2)}px"></div> -->
          </div>
        </div>
      `),
        });
    }
    initKpiCollapse() {
        $('.kpi-block').toArray().map(e => $(e)).map($block => {
            $block.find('.legend-label').on('click', (e) => {
                if ($block.is('.kpi-collapsable')) {
                    $block.toggleClass('kpi-closed');
                }
            });
            $block.on('click', (e) => {
                if ($block.is('.cvs-done-step')) {
                    const targetStep = $block.attr('step');
                    this.goToStep(Number(targetStep));
                }
            });
        });
    }
    listenToEvents() {
        cvs.listen(() => {
            if (this.selected === undefined) {
                this.selectNextInQueue().catch(AError.handle);
            }
            this.updateQueues();
        });
        cvs.verdictObservable.listen((verdict) => {
            if (!verdict)
                return;
            this.verdictHistoryTable.store.insert(0, verdict);
            $('#verdict-history > .legend-label > span').text(`(${cvs.verdicts.value.length})`);
        });
    }
    bindShortcuts(doc) {
        $(doc).off('keyup');
        $(doc).on('keyup', (e) => {
            this.onKeyPressed(e.key, e.shiftKey);
        });
    }
    async onKeyPressed(key, shiftPressed) {
        const $focus = $(':focus');
        if (this.selected !== undefined && this.isEnforcing && this.hasDetections && !$focus.is('input') && !Alerts.isVisible) {
            if ($focus.is('textarea') && shiftPressed === true) {
                return;
            }
            const shortcuts = {
                'Enter': () => this.nextStep()
            };
            if (shortcuts.hasOwnProperty(key)) {
                shortcuts[key]();
            }
            else {
                const $foundShortcut = $(`[step="${this.step}"] [stepshortcut="${key}"]:visible`);
                if ($foundShortcut.length > 1) {
                    throw new Error(`Multiple shortcuts found for shortcut="${key}"`);
                }
                $foundShortcut.trigger('click');
            }
        }
    }
    splitView() {
        if (this.windowRelay && this.windowRelay.doc) {
            this.windowRelay.close();
        }
        this.windowRelay = new AWindowRelay({
            title: 'Central Verification #2',
            html: ( /*html*/`
        <div id="image-view" class="is-double-view columns col-gapless" style="margin: 15px;">
          <style>
            .photos img {
              background: #fff;
            }

            #image-view:not(.is-double-view) #photos-proxy {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              align-content: space-between;
              gap: 0.4rem;
            }
            
            #image-view.is-double-view #photos-proxy img,
            #image-view.is-double-view #photos-proxy-linked img {
              display: block;
              max-width: -webkit-fill-available !important;
              margin-bottom: 15px;
            }

            .imageContainer .btn i {
              line-height: 1rem;
            }
          </style>
          <div id="target" class="column col-6">
            <div id="photos-proxy" class="photos photos-scroll-container"></div>
          </div>
          <div id="target-linked" class="column col-6">
            <div id="photos-proxy-linked" class="photos photos-scroll-container"></div>
          </div>
        </div>
      `)
        });
        this.windowRelay.onLoad(() => {
            debugger;
            $('#split-view').prop('disabled', true);
            const $photosProxy = $('#photos-proxy').detach();
            $photosProxy.removeClass('hidden');
            const $t = $(this.windowRelay?.getElementById('target'));
            $t.append($photosProxy);
            const imageHelperSelectors = ['#photos-proxy', '#photos-proxy-linked'];
            const [imageHelperProxy, linkedImageHelperProxy] = imageHelperSelectors.map(qs => {
                let ih = new AImageHelper({
                    $photos: this.windowRelay.$(qs),
                    $rh: this.windowRelay.$(qs),
                    $: this.windowRelay.$,
                });
                ih.setWindowRelay(this.windowRelay);
                return ih;
            });
            this.imageHelperProxy = imageHelperProxy;
            this.linkedImageHelperProxy = linkedImageHelperProxy;
            if (this.selected) {
                this.clearImages();
                this.loadImages().catch(AError.handle);
            }
            $('#central-verification > .column:not(.cvs-top)').removeClass('col-3 col-4').addClass('col-6');
            $('#central-verification > .column:not(.cvs-top)').last().addClass('hidden');
        });
        this.windowRelay.onClose(() => {
            $('#split-view').prop('disabled', false);
            console.log('windowRelay closed');
            this.imageHelperProxy?.removeAllEvents();
            this.imageHelperProxy = undefined;
            $('#central-verification > .column:not(.cvs-top)').last().removeClass('hidden');
            if (this.isTimeLimitedParking) {
                $('#central-verification > .column:not(.cvs-top)').slice(0, 2).addClass('col-4');
                $('#central-verification > .column:not(.cvs-top)').slice(-1).addClass('col-4');
            }
            else {
                $('#central-verification > .column:not(.cvs-top)').addClass('col-4');
            }
            this.clearImages();
            this.loadImages().catch(AError.handle);
            // $('#photos-primary').closest('.kpi-block').removeClass('hidden')
        });
    }
    cancelFineClick(detection, recordRef) {
        cvs.cancelFine(detection);
        if (!recordRef) {
            recordRef = this.verdictHistoryTable.store.find((r) => {
                return (r.DetectionId === detection.DetectionId && r.DetectionDeviceId === detection.DetectionDeviceId);
            });
        }
        if (recordRef) {
            recordRef.ColorCls = '';
            recordRef.Canceled = true;
            this.verdictHistoryTable.refreshRows();
        }
    }
    getCurrentChannelName() {
        let channel = channelService.findChannel(this.selected?.VerificationChannel)
            || channelService.getActiveChannels().pop()
            || { Key: 'default', Name: this.t['default'] };
        return channel?.Name;
    }
    async redrawVerdictForm() {
        await Loading.waitForPromises(this.genVerdictForm());
    }
    async editOffenceCode(detection) {
        const Regimes = Config.Regimes;
        if (!Regimes) {
            console.warn(`Config.Regimes is not defined!`);
            return;
        }
        const size = Math.max(1, Object.keys(Regimes).length ?? 1);
        const events = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Edit Offence Code')),
            buttons: ALERT_BUTTONS.saveCancel,
            skipRegex: true,
            content: ( /*html*/`
        <div class="form-select-container input-group">
          ${(this.showOffenceCodes) ? ( /*html*/`
            <select class="form-select form-focus" id="RegimeCode" name="RegimeCode" size="${size}">
              ${Object.keys(Regimes).map(key => /*html*/ `
                  <option value="${key}">${escapeHtml(Regimes[key].RegimeText).padEnd(6, ' ').replace(/ /g, '&nbsp;')}</option>
                `).join('')}
            </select>
          `) : ''}
          <select class="form-select form-focus" id="OffenceText" name="OffenceText" size="${size}">
          ${Object.keys(Regimes).map(key => /*html*/ `
              <option value="${key}">${escapeHtml(Regimes[key].OffenceText)}</option>
            `).join('')}
          </select>
        </div>
      `)
        });
        const $offenceInputs = events.$ele.find('#RegimeCode,#OffenceText');
        const $regimeCode = $offenceInputs.eq(0);
        $offenceInputs.on('pointerdown mousedown input', (e) => {
            $offenceInputs.val($(e.target).val());
        });
        $offenceInputs.val(detection.OffenceCode);
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                this.selected = undefined;
                await Loading.waitForPromises(
                // cvs.detectionChangeOffenceCode(detection, {
                //   OffenceCode: $offenceCode.val()! as string
                // })
                cvs.detectionChangeRegimeCode(detection, {
                    RegimeCode: $regimeCode.val()
                }));
                await this.selectNextInQueue();
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
    initDetectionInputs() {
        // Country Code Init
        const $cc = $('.input-cc');
        $cc.on('keyup keypress blur change', (e) => {
            $cc.val($cc.val().toUpperCase());
        });
        // License Plate Init
        const $lp = $('.input-lp');
        $lp.on('keyup keypress blur change', (e) => {
            $lp.val($lp.val().toUpperCase());
        });
        $lp.on('stretch', (e) => {
            $lp.val($lp.val().toUpperCase());
        });
        $lp.trigger('keyup').trigger('stretch');
        $lp.on('keyup keypress blur change stretch', async (e) => {
            await waitForChromeFrame();
            const newValue = $lp.val().toUpperCase();
            $('.lp-render').remove();
            // let offsetLeft: number = 6
            const $lpRender = $(/*html*/ `<div class="lp-render"></div>`);
            for (let i = 0; i < newValue.length; i++) {
                const isNum = /^\d+$/.test(newValue.charAt(i));
                const $newChar = $(/*html*/ `
          <span class="input-lp-char"style="line-height: ${$lp.outerHeight()}px;">
            ${newValue.charAt(i)}
          </span>
        `);
                $newChar.css('color', (isNum) ? 'var(--main-color)' : '#000');
                $lpRender.append($newChar);
            }
            $lp.after($lpRender);
        });
        $('.input-lp,.input-cc').on('keydown', async (e) => {
            try {
                if (this.selected === undefined) {
                    e.preventDefault();
                    return;
                }
                const { DetectionId, DetectionDeviceId, LicensePlate, CountryCode } = this.selected;
                if (e.key === 'Enter') {
                    const NewLicensePlate = ($('.input-lp').val() || LicensePlate);
                    const NewCountryCode = ($('.input-cc').val() || CountryCode);
                    if (LICENSE_PLATE_COUNTRY_CODES[NewCountryCode] === undefined) {
                        e.preventDefault();
                        Alerts.show({
                            title: ALERT_TITLES.Warning,
                            content: ( /*html*/`
              ${this.t['Country Code could not be found!']} (code = ${NewCountryCode})<br/>

            `)
                        }).on(ALERT_STATUS.ON_MODAL_CLOSED, ({ action }) => [
                            $('.input-cc').trigger('focus')
                        ]);
                        var activeElement = document.activeElement;
                        activeElement.blur();
                        return;
                    }
                    // const lpChanged = LicensePlate.toUpperCase() !== NewLicensePlate.toUpperCase()
                    // const ccChanged = CountryCode.toUpperCase() !== NewCountryCode.toUpperCase()
                    if (LicensePlate.toUpperCase() === NewLicensePlate.toUpperCase() && CountryCode.toUpperCase() === NewCountryCode.toUpperCase()) {
                        var activeElement = document.activeElement;
                        activeElement.blur();
                        // this.onKeyDown(e.key)
                        return;
                    }
                    const selected = this.selected;
                    this.selected = undefined;
                    await Loading.waitForPromises(cvs.detectionChangeRequest(selected, {
                        DetectionId,
                        DetectionDeviceId,
                        LicensePlate,
                        NewValues: {
                            LicensePlate: NewLicensePlate,
                            CountryCode: NewCountryCode
                        }
                    }), { blockParent: '#central-verification' });
                    await this.selectNextInQueue();
                }
            }
            catch (err) {
                AError.handle(err);
            }
        });
        const $remarks = $('#remarks');
        $remarks.on('keypress', (e) => {
            if (e.key === 'Enter' && e.shiftKey !== true && ($remarks.val() || '').trim().length === 0) {
                e.preventDefault();
            }
        });
    }
    async recheckPrdb() {
        try {
            if (!this.selected) {
                return;
            }
            const selected = this.selected;
            this.selected = undefined;
            await cvs.detectionPermitRecheck(selected);
            await this.selectNextInQueue();
        }
        catch (err) {
            AError.handle(err);
        }
    }
    sendVerdict(opt, context) {
        const { DetectionId, DetectionDeviceId, LicensePlate } = this.selected;
        const verifyResponse = {
            DetectionId,
            DetectionDeviceId,
            LicensePlate,
            // the lat/lon in the VerifyResponse is the device lat/lon, not a copy of the detection lat, lon
            Latitude: null,
            Longitude: null,
            ...opt,
        };
        cvs.sendVerdict(verifyResponse, context);
        this.selected = undefined;
        this.selectNextInQueue().catch(AError.handle);
    }
    resetSteps() {
        $('.cvs-step').css('opacity', 1);
        $('.cvs-step').removeClass('cvs-curr-step');
        $('.cvs-step').removeClass('cvs-done-step');
        $('.cvs-step .cvs-step-instruction').text('');
        $('.cvs-step').toggleClass('kpi-closed', this.showOneStepConcurrently);
        this.step = APageSteps.Initial;
        this.updateButtons();
    }
    goToStep(targetStep) {
        this.resetSteps();
        while (this.step >= 0 && this.step <= APageSteps.Verdict && this.step !== targetStep) {
            this.nextStep();
        }
    }
    prevStep() {
        this.goToStep(this.step - 1);
    }
    nextStep() {
        if (this.step === APageSteps.Verdict) {
            return;
        }
        const $prevStep = $(`.cvs-step[step="${this.step}"]`);
        $prevStep.closest('.kpi-block').addClass('kpi-closed');
        this.step = (this.step + 1);
        $('.cvs-step .cvs-step-instruction').text('');
        $('.cvs-step').css('opacity', 0.7);
        $('.cvs-step.cvs-curr-step').removeClass('cvs-curr-step').addClass('cvs-done-step');
        const $step = $(`.cvs-step[step="${this.step}"]`);
        $step.addClass('cvs-curr-step');
        $step.toggleClass('kpi-closed', false);
        $step.find('.cvs-step-instruction').text(this.t[`Press "Enter" To Continue`]);
        if (this.step === APageSteps.Remarks) {
            $('#remarks').trigger('focus');
        }
        if (this.step === APageSteps.Verdict) {
            // console.log('last step')
        }
        this.updateButtons();
    }
    async initAutoVerdict(opt) {
        this.autoVerdictEnabled = true;
        if (!this.selected) {
            return;
        }
        while (this.step !== APageSteps.Verdict) {
            this.nextStep();
            await sleep(10);
        }
        let waitingForNext = true;
        Loading.waitForEvent('VerifyRequest', true).then(() => {
            waitingForNext = false;
        });
        while (waitingForNext) {
            const $modalProceedBtn = $('.modal.active #option1');
            if ($modalProceedBtn.length) {
                $modalProceedBtn.trigger('click');
            }
            else {
                const $verdictBtnArr = $('#verdict-form').find('[verdictcode]').toArray().map(btn => $(btn));
                if ($verdictBtnArr.length > 0) {
                    const $btn = $verdictBtnArr[Math.floor(Math.random() * $verdictBtnArr.length)];
                    // $btn.trigger('click')
                    this.verdictRoute.push(Number($btn.attr('btnindex')));
                    const data = await this._genVerdictForm();
                    if (data?.verdictSent === true) {
                        waitingForNext = false;
                    }
                    $('.modal.active #option1').trigger('click');
                }
            }
            sleep(10);
        }
    }
    updateQueues(channelStats) {
        const stats = [...(channelStats ?? queueService.cachedChannelStats)];
        const localQueue = cvs.queue.length + (this.selected ? 1 : 0);
        $('.localqueue').text(localQueue);
        const { activeChannelKeys } = channelService;
        let sumStats = {};
        for (const stat of stats) {
            if (activeChannelKeys.includes(stat.Channel)) {
                Object.keys(stat).map(key => {
                    if (typeof stat[key] === 'number') {
                        sumStats[key] = (sumStats[key] ?? 0) + stat[key];
                    }
                });
            }
        }
        // const index = stats.findIndex(c => c.Channel === cvs.activeChannels?.Key)
        // const currChannelStats = stats[index]
        $('.remotequeue').text(sumStats?.CentralVerificationQueue ?? -100);
        $('.totalqueue').text((sumStats?.CentralVerificationQueue ?? -100) + localQueue);
        // const pdaQueues = stats.map(c => c.PdaVerificationQueue + c.AssignedPdaVerifications).reduce((a, b) => a + b, 0)
        $('.pdaqueue').text((sumStats?.PdaVerificationQueue ?? -100));
    }
    async selectNextInQueue() {
        if (this.selected) {
            console.error('Skipped selecting next in queue because the current needs a verdict!', this.selected);
            return;
        }
        const prevSelected = this.selected;
        this.markers?.map(m => m.setMap(null));
        this.meterRef?.stop();
        this.selected = cvs.queue.shift();
        console.log('SELECTING NEXT IN QUEUE, prev=', prevSelected, ' current=', this.selected);
        await this.redrawVerdictForm();
        await Loading.waitForPromises(queueService.refreshChannelStats({ triggerEvent: true }));
        this.resetSteps();
        this.clearImages();
        geoPaintService.changeOpacityAll(this.parkingMachines, 0.2);
        if (this.selected !== undefined) {
            const detections = [this.selected];
            if (this.linkedDetection) {
                detections.push(this.linkedDetection);
            }
            this.markers = detections.map((detection, i) => {
                let { Latitude, Longitude, VehicleBounds } = detection;
                if (!AIsLatLngValid([Latitude, Longitude])) {
                    AError.handleSilent(`Detection doesnt have a valid latitude & longitude!`);
                }
                const { coordinates } = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
                const marker = new google.maps.Polygon({
                    paths: coordinates,
                    strokeColor: ['#ff0000', '#0000ff'][i % 2],
                    strokeOpacity: 1,
                    fillColor: ['#ff0000', '#0000ff'][i % 2],
                    fillOpacity: 0.9,
                    strokeWeight: 3,
                    zIndex: 10.0,
                    map: this.map
                });
                this.map.setCenter(new google.maps.LatLng(Latitude, Longitude));
                this.map.setZoom(20);
                return marker;
            });
            const linkedMachines = detections.map((d, i) => d.GeoMatches.ParkingMachine).flat().filter(v => v != null);
            geoService.setCachedAttributes('ParkingMachine', linkedMachines);
            const linkedMachineGeoIds = linkedMachines.map(m => m.GeoId);
            const machinesToHighlight = this.parkingMachines.filter(m => linkedMachineGeoIds.includes(m.data?.GeoId ?? -1));
            geoPaintService.paintAll(machinesToHighlight);
            this.updateParkingMachines(machinesToHighlight);
        }
        else {
            this.updateParkingMachines();
        }
        this.updateAdjacentData();
        this.updateQueues();
        this.updateMeter();
        this.updateMeta().catch(AError.handle);
        this.updateSuspicion();
        this.updateNotes();
        this.updateAddress();
        this.updateButtons();
        this.loadImages().catch(AError.handle);
        this.goToStep(APageSteps.LicensePlate);
        if (this.selected && this.autoVerdictEnabled) {
            this.initAutoVerdict();
        }
    }
    updateMeter() {
        const $container = $('#meter-container');
        $container.html('');
        if (this.selected === undefined) {
            return;
        }
        const { DetectionId, DetectionDeviceId, VerificationExpireTime } = this.selected;
        const expire = new Date(VerificationExpireTime);
        const timeout = (expire.getTime() - Date.now()) / 1000.0;
        // const timeout = ADurationToSeconds(Config.ShelfLife.Detection)
        this.meterRef = AEngine.get(AMeterService).gen({
            // expire: new Date(new Date(this.selected!.DetectionTime).getTime() + timeout * 1000),
            expire,
            timeout,
            onStart: ({ $meter }) => {
                $container.append($meter);
                const h = $meter.outerHeight();
                $meter.find('.bar-time-left').css('line-height', `${ARound(h || 24, 2)}px`);
            },
            onRefresh: ({ $meter, timeleft, progress }) => {
                $meter.find('.bar-time-left').text(`${timeleft}`);
                $meter.find('.bar-progress').css('right', `${ARound(progress, 2)}%`);
                let defaultColor = new AColor(AColor.hexToInt('009ec5')).hsv;
                const lerpOptions = [
                    { perc: 0, color: defaultColor },
                    { perc: 50, color: defaultColor },
                    { perc: 66.667, color: new AColor(255, 255, 0).hsv },
                    { perc: 83.333, color: new AColor(255, 128, 0).hsv },
                    { perc: 100, color: new AColor(255, 0, 0).hsv },
                ];
                const fullWidth = $meter.find('.bar-time-left').width() || 0;
                const currWidth = $meter.find('.bar-progress').width() || 0;
                const barPerc = currWidth * 100.0 / fullWidth;
                let start = { perc: -1, color: new AColor(0, 0, 0).hsv };
                let stop = { perc: 101, color: new AColor(0, 0, 0).hsv };
                lerpOptions.sort(v => v.perc).map((v) => { start = (v.perc < barPerc) ? v : start; });
                lerpOptions.sort(v => -v.perc).map((v) => { stop = (v.perc >= barPerc) ? v : stop; });
                const t = (barPerc - start.perc) / (stop.perc - start.perc);
                const barColor = start.color.lerpTo(stop.color, t).hexi;
                $meter.find('.bar-progress').css('background', barColor);
                // console.log(`%cFROM %cTO`, `color: #000;background: ${start.color.hexi}`, `color: #000;background: ${stop.color.hexi}`)
                // console.log(`%cCOLOR t=${t}`, `color: #000;background: ${newColor}`)
            },
            onFinish: (a, b) => {
                console.log('onFinish', a, this.step);
                if (this.selected && this.selected.DetectionId === DetectionId && this.selected.DetectionDeviceId === DetectionDeviceId) {
                    this.sendVerdict({
                        Result: 'NOT_PROCESSED|CENTRAL_VERIFICATION_TIMEOUT',
                        ResultText: 'Unhandled - Time Expired',
                    }, { AllowCancel: false, Timestamp: Date.now() });
                }
                else {
                    const errorData = {
                        selectedHasValue: this.selected !== undefined,
                        selected: this.selected,
                        meterRef: { DetectionId, DetectionDeviceId }
                    };
                    AError.handle(new Error(`MeterService onFinish callback out of sync! ${JSON.stringify(errorData)}`));
                }
            }
        });
    }
    extractRemarks() {
        const $remarks = $('#remarks');
        const val = ($remarks.val() || '').trim();
        const output = (val.length > 0) ? val : undefined;
        $remarks.val('');
        return output;
    }
    async updateNotes() {
        const data = this.selected;
        $('#kpi-cancel').toggleClass('hidden', data?.VerificationDisplayText ? false : true);
        const [user, reason] = (data?.VerificationDisplayText || ':').split(':');
        $(`[data-id="notes"] [data-prop="CancelUser"]`).text(user || '');
        $(`[data-id="notes"] [data-prop="CancelReason"]`).text(reason || '');
    }
    findDifferenceIndex(a, b) {
        if (a.length === 0 || b.length === 0)
            return -1;
        for (let i = a.length - 1; i >= 0; i--) {
            if (a[i] !== b[i]) {
                console.log(i);
                console.log('same', a.substring(0, i), b.substring(0, i));
                console.log('diff', a.substring(i), b.substring(i));
                return i;
            }
        }
        return -1;
    }
    highlightDifference(opt) {
        let { FirstSeen, LastSeen } = opt;
        const diffIndex = this.findDifferenceIndex(FirstSeen, LastSeen);
        if (diffIndex !== -1) {
            FirstSeen = FirstSeen.substring(0, diffIndex) + `<b>` + FirstSeen.substring(diffIndex) + `</b>`;
            LastSeen = LastSeen.substring(0, diffIndex) + `<b>` + LastSeen.substring(diffIndex) + `</b>`;
        }
        return { FirstSeen, LastSeen };
    }
    updateSuspicion() {
        const data = this.selected;
        const DetectionTime = data ? AFormatDate(new Date(data.DetectionTime)) : '';
        const hasMultipleMarkers = (this.markers !== undefined && this.markers.length > 1);
        $(`[data-id="suspicion"] [data-prop="FirstDetectionTime"]`).closest('tr').toggleClass('hidden', !hasMultipleMarkers);
        $(`[data-id="suspicion"] [data-prop="LastDetectionTime"]`).closest('tr').toggleClass('hidden', !hasMultipleMarkers);
        $(`[data-id="suspicion"] [data-prop="DetectionTime"]`).closest('tr').toggleClass('hidden', hasMultipleMarkers);
        $(`[data-id="suspicion"] [data-prop="Channel"]`).text(this.getCurrentChannelName());
        $(`[data-id="suspicion"] [data-prop="HasParkingRight"]`).html(COLUMN_BOOLEAN_NULLABLE.renderer({ value: data?.HasParkingRight ?? null }));
        $(`[data-id="suspicion"] [data-prop="DetectionTime"]`).text(DetectionTime);
        if (this.linkedDetection) {
            const diff = this.highlightDifference({
                FirstSeen: this.markers?.length !== 1 && data ? AFormatDate(new Date(this.linkedDetection.DetectionTime)) : '',
                LastSeen: DetectionTime
            });
            $(`[data-id="suspicion"] [data-prop="FirstDetectionTime"]`).html(diff.FirstSeen);
            $(`[data-id="suspicion"] [data-prop="LastDetectionTime"]`).html(diff.LastSeen);
        }
        $(`[data-id="suspicion"] [data-prop="OffenceCode"]`).html(data?.OffenceCode ? ( /*html*/`
        <span>${data.OffenceText ? escapeHtml(data.OffenceText) ?? '' : ''}</span>
        <i class="edit-offence-code fa-solid fa-pen fa-lg"></i>
      `) : '');
        $(`[data-id="suspicion"] [data-prop="OffenceCode"] .edit-offence-code`).on('click', (e) => {
            this.editOffenceCode(this.selected);
        });
        $(`[data-id="suspicion"] [data-prop="OffenceDescription"]`).text(data?.OffenceDescription || '');
        $(`[data-id="suspicion"] [data-prop="OffencePrice"]`).text(data && data.OffencePrice !== null ? formatPrice(data.OffencePrice) : '');
    }
    updateAdjacentData() {
        const CustomDisplayFields = this.selected?.CustomDisplayFields ?? {};
        const scrollTop = this.additionalDataTable.storeScroll();
        if (Object.keys(CustomDisplayFields).length > 0) {
            $('#custom-data').removeClass('hidden');
        }
        this.additionalDataTable.store.data = Object.keys(CustomDisplayFields).map(Attr => ({ Attr, Value: CustomDisplayFields[Attr] ?? '' }));
        this.additionalDataTable.restoreScroll(scrollTop);
    }
    updateParkingMachines(machines) {
        // const data = this.selected
        $('#parking-machines > .legend-label > span').text(`(${machines?.length ?? 0})`);
        const scrollTop = this.parkingMachinesTable.storeScroll();
        const vehiclePos = { lng: this.selected?.Longitude ?? 0, lat: this.selected?.Latitude ?? 0 };
        this.parkingMachinesTable.store.data = machines?.map(m => {
            const pos = AGeoUtils.calcCenter(m);
            const attrs = m?.data?.Attributes;
            return {
                ...pos,
                distance: distanceGoogleMaps(pos, vehiclePos),
                displayName: attrs?.displayName,
                // outOfOrder: attrs?.outOfOrder,
                inOrder: attrs && attrs.outOfOrder !== true,
                remainingTickets: attrs?.remainingTickets,
                interventionRequest: attrs?.interventionRequest,
                providerId: attrs?.providerId,
                urbanParkingSiteId: attrs?.urbanParkingSiteId,
                airBatteryLevel: attrs?.airBatteryLevel,
                leadAcidBatteryLevel: attrs?.leadAcidBatteryLevel,
                lastEventDate: attrs?.lastEventDate,
            };
        }).sort((a, b) => (a.distance - b.distance));
        this.parkingMachinesTable.restoreScroll(scrollTop);
    }
    updateAddress() {
        const { DetectionUser, DetectionDevice, Confidence } = this.selected ?? {};
        const { ClosestAddress, DetectionStreet, PostalCode } = Object.assign({}, this.selected?.Location);
        $(`[data-id="address"] [data-prop="ClosestAddress"]`).text(ClosestAddress || '');
        $(`[data-id="address"] [data-prop="DetectionStreet"]`).text(DetectionStreet || '');
        $(`[data-id="address"] [data-prop="PostalCode"]`).text(PostalCode || '');
        $(`[data-id="address"] [data-prop="DetectionUser"]`).text(DetectionUser || '');
        $(`[data-id="address"] [data-prop="DetectionDevice"]`).text(DetectionDevice || '');
    }
    updateLpForm() {
        const detection = this.selected;
        $('#reliability').text(detection ? Math.round(detection.Confidence * 100.0) + '%' : '-');
        $('.input-cc').val((detection !== undefined) ? detection.CountryCode.toUpperCase() : '');
        $('.input-lp').val((detection !== undefined) ? detection.LicensePlate.toUpperCase() : '');
        $('.input-lp').trigger('stretch');
        const w = $('.input-lp').outerWidth();
        $('#lp-form .license-plate').width(w);
        // $('.input-lp-parent').width($('.input-lp').outerWidth()!)
        // $('#lp-form .input-lp-parent').width(w)
        $(`[step="${APageSteps.LicensePlate}"]`).toggleClass('kpi-closed', (detection === undefined || this.step > APageSteps.LicensePlate));
    }
    async updateMeta() {
        const data = {
            Color: '',
            Brand: '',
            Type: '',
        };
        const $renderFields = $('[data-id="meta"] [data-prop]');
        $renderFields.toArray().map(e => $(e)).map($ele => {
            const propertyName = $ele.attr('data-prop') || '';
            if (!data.hasOwnProperty(propertyName)) {
                throw new Error(`Expected property ${propertyName}`);
            }
            $ele.text(data[propertyName]);
        });
    }
    updateButtons() {
        const lpForm = this.step !== APageSteps.LicensePlate || !this.isEnforcing || !this.hasDetections;
        $('#lp-form input').prop('disabled', lpForm);
        const verdictForm = this.step !== APageSteps.Verdict || !this.isEnforcing || !this.hasDetections;
        $('#verdict-form input, #verdict-form button, #verdict-form textarea').prop('disabled', verdictForm);
        $('#history').prop('disabled', !this.isEnforcing || !this.hasDetections);
        this.updateLpForm();
    }
    async clearImages() {
        this.imageHelpers.map((imageHelper) => {
            imageHelper.clearImageSet();
            imageHelper.setLPImage();
            if (imageHelper.proxyWindow) {
                imageHelper.$photos.html('');
            }
        });
    }
    updateViewTemplate() {
        $('#view-primary').toggleClass('col-6', this.isTimeLimitedParking);
        $('#view-primary').toggleClass('col-12', !this.isTimeLimitedParking);
        $('#view-secondary').toggleClass('hidden', !this.isTimeLimitedParking);
        if (this.windowRelay !== undefined) {
            const windowRelay = this.windowRelay;
            const { $ } = windowRelay;
            $('#image-view').toggleClass('is-double-view', this.isTimeLimitedParking);
            const $columns = $('#image-view > .column');
            $columns.eq(0).toggleClass('col-6', this.isTimeLimitedParking);
            $columns.eq(0).toggleClass('col-12', !this.isTimeLimitedParking);
            $columns.eq(1).toggleClass('hidden', !this.isTimeLimitedParking);
        }
    }
    async loadImages() {
        this.updateViewTemplate();
        await Promise.all([
            this.selected ? this.loadImagesFor(this.selected, this.imageHelpers) : Promise.resolve(),
            this.isTimeLimitedParking ? this.loadImagesFor(this.linkedDetection, this.linkedImageHelpers) : Promise.resolve()
        ]);
    }
    async loadImagesFor(detection, imageHelpers) {
        const { LPImage, CarImage, OverviewImages } = detection;
        const imageData = {
            LPImage: LPImage,
            images: [
                ...[AUrlEncodedImageFromBase64(CarImage)],
                ...OverviewImages.map(img => AUrlEncodedImageFromBase64(img)),
            ]
        };
        let fetchedImages = (imageData.images && imageData.images.length) ?
            imageData.images.map(img => { return { src: img }; }) :
            await this.photoFetcher.fetchOverviewPhoto(imageData.DetectionId, imageData.DetectionDeviceId);
        imageHelpers.map((imageHelper, i) => {
            imageHelper.clearImageSet();
            imageHelper.setLPImage(imageData.LPImage);
            const showOneImage = false;
            if (showOneImage && fetchedImages.length) {
                fetchedImages = [fetchedImages[this.imgIndex]];
            }
            let width = imageHelper.$photos.width();
            // AEngine.log(`AImageHelper #${i} hasProxyWindow:`, imageHelper.hasProxyWindow)
            if (imageHelper.hasProxyWindow) {
                width = Math.min(width, 400);
            }
            imageHelper.addImageSet(fetchedImages.map(obj => obj.src), {
                allowFilter: true,
                allowFullscreen: true,
                allowZoom: false,
                defaultSize: {
                    height: null,
                    width: width - 10
                }
            });
        });
    }
    async genVerdictForm() {
        const formOptions = channelService.getVerificationResultOptions(this.selected);
        const recursiveInheritance = async (curr, parent) => {
            if (curr.IsTextTranslated !== true && curr.Code) {
                // let before = curr.Text
                curr.Text = await Translate.get(curr.Code);
                curr.IsTextTranslated = true;
                // AEngine.log(`translated %c\t${before}\nto \t\t${curr.Text}`)
            }
            if (!curr.ColorCls && parent?.ColorCls !== undefined) {
                curr.ColorCls = parent.ColorCls;
            }
            if (curr.Options !== undefined) {
                curr.Options = await Promise.all(curr.Options.map(v => recursiveInheritance(v, curr)));
            }
            return curr;
        };
        this.baseFormOptions = await recursiveInheritance({ Options: formOptions });
        this._genVerdictForm();
    }
    async shouldSendVerdict(opt) {
        const t = await Loading.waitForPromises(Translate.get(`Are you sure you want to fine this detection?`));
        return new Promise((resolve) => {
            if (opt.AllowCancel !== true) {
                return resolve(true);
            }
            if (this.autoVerdictEnabled) {
                resolve(true);
                return;
            }
            const events = Alerts.show({
                title: ALERT_TITLES.Warning,
                content: t,
                buttons: ALERT_BUTTONS.yesNo
            }).on(ALERT_STATUS.ON_MODAL_CLOSED, (opt) => resolve((opt.action === ALERT_STATUS.ON_ACTION_PROCEED)));
        });
    }
    async _genVerdictForm() {
        if ((this.baseFormOptions.Options || []).length === 0) {
            AError.handle(new Error(`VerifyResultOptions have not been set up properly!`));
            // AError.handleSilent(, ERROR_GROUPS.InvalidVerifyResultOptions)
            // Alerts.internalServerError()
            return;
        }
        let currVerdictOpt = this.baseFormOptions;
        for (const optIndex of this.verdictRoute) {
            currVerdictOpt = currVerdictOpt.Options[optIndex];
        }
        if (currVerdictOpt.Options === undefined || currVerdictOpt.Options.length === 0) {
            return await this.shouldSendVerdict(currVerdictOpt).then((proceed) => {
                if (!proceed) {
                    this.verdictRoute.pop();
                    currVerdictOpt = this.baseFormOptions;
                    for (const optIndex of this.verdictRoute) {
                        currVerdictOpt = currVerdictOpt.Options[optIndex];
                    }
                    return;
                }
                this.verdictRoute = [];
                const { Code, Text, AllowCancel, ColorCls } = currVerdictOpt;
                this.sendVerdict({
                    Result: Code,
                    ResultText: Text,
                    Remarks: this.extractRemarks()
                }, {
                    AllowCancel: AllowCancel || false,
                    ColorCls: ColorCls,
                    Timestamp: Date.now()
                });
                return { verdictSent: true };
            });
            // return
        }
        const $btns = currVerdictOpt.Options.filter(verdictOpt => verdictOpt.Visible !== false).map((verdictOpt, i) => {
            const { Code, Text, IconCls, ColorCls } = verdictOpt;
            const shortcut = (i + 1);
            const $parent = $(/*html*/ `
        <div class="column col-12">
          <button btnindex="${i}" stepshortcut="${shortcut}" verdictcode="${Code}" class="btn ${transformButtonCls(ColorCls)} full-width mb-1">
            ${IconCls ? `<i class="${IconCls}"></i>` : `<i class="fa-solid fa-barcode"></i>`}
            ${Text} ${(shortcut < 10) ? `(${shortcut})` : ''}
          </button>
        </div>
      `);
            $parent.find('button').on('click', (e) => {
                this.verdictRoute.push(i);
                this._genVerdictForm();
            });
            return $parent;
        });
        const $backBtnCtr = $(/*html*/ `
      <div class="column col-12 ${(this.verdictRoute.length === 0) ? 'hidden' : ''}">
        <button class="btn btn-grey full-width mb-1 v-go-back">
          <i class="fa-regular fa-chevron-left"></i>
          ${this.t['go back']}
        </button>
      </div>
    `);
        $backBtnCtr.find('.v-go-back').on('click', () => {
            this.verdictRoute.pop();
            this._genVerdictForm();
        });
        const $formCols = $('#verdict-form > .columns');
        $formCols.html('');
        $formCols.append($backBtnCtr);
        $btns.map($btn => $formCols.append($btn));
    }
    async refresh() {
    }
}
export function css() {
    return ( /*html*/`
    <style>
      @font-face {
        font-family: 'Kenteken';
        src: url('/font/Kenteken.ttf') format('truetype');
        font-weight: normal;
      }

      :root {
        --cvs-top: 0px; /*81px;*/
      }

      .cc-wrapper, .lp-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-end;
      }

      #central-verification {
        height: 100%;
        position: relative;
      }

      #central-verification > .cvs-top {
        height: var(--cvs-top);
        margin-bottom: 15px;
      }

      #central-verification > .column:not(.cvs-top) {
        height: 100%;
        padding-bottom: 15px;
        overflow-y: auto;
      }

      #central-verification > .column.cvs-top .kpi-block {
        padding: 25px 15px;
      }

      #stats > .tooltip i {
        line-height: 36px;
      }

      #stats > .tooltip {
        text-align: center;
      }

      .custom-time-bar {
        position: relative;
        height: 100%;
        min-width: 160px;
        background: #e0f9ff;
        color: #fff;
        overflow: hidden;
      }
      .custom-time-bar .bar-time-left {
        position: absolute;
        text-align: center;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 200;
        color: #000;
      }
      .custom-time-bar .bar-progress {
        display: block;
        position: absolute;
        background: var(--main-color);
        left: 0;
        top: 0;
        right: 100%;
        bottom: 0;
        transition: right 1.0s;
      }

      .kpi-photos {
        text-align: center;
        overflow-y: auto;
        height: calc(100% - 15px)
      }

      .cvs-curr-step,
      .cvs-done-step {
        opacity: 1 !important;
      }
      
      .cvs-done-step {
        cursor: pointer;
      }

      .cvs-step:not(.cvs-done-step) .legend-label {
        margin-bottom: 10px;
      }

      .cvs-done-step .legend-label::after {
        content: '\\f00c';
        display: inline-block;
        font-family: 'Font Awesome 6 Pro';
        color: #00c500;
        font-size: 18px;
        font-weight: bold;
        text-shadow: 0 0px 1px #00000091;
        padding: 0 5px;
      }

      .kpi-block.kpi-closed {
        max-height: 50px;
        overflow: hidden;
      }

      .kpi-block.kpi-closed .legend-label {
        margin: 0 !important;
      }

      .kpi-block.kpi-collapsable .legend-label {
        margin-bottom: 10px;
      }

      .kpi-block.kpi-closed.kpi-collapsable > .legend-label > span {
        display: inline !important;
      }

      .kpi-block.kpi-closed > :not(.legend-label),
      .kpi-block.kpi-closed > :not(.legend-label) * {
        display: none;
      }

      .kpi-block.kpi-collapsable .legend-label:hover {
        cursor: pointer;
      }

      .kpi-block.kpi-collapsable .legend-label::after {
        content: '\\f077';
        display: inline-block;
        font-family: 'Font Awesome 6 Pro';
        color: inherit;
        font-size: 18px;
        font-weight: bold;
        text-shadow: 0 0px 1px #00000091;
        padding: 0 5px;
        float: right;
      }

      .kpi-block.kpi-collapsable.kpi-closed .legend-label::after {
        content: '\\f078';
      }

      .cvs-step-instruction {
        color: var(--main-color);
      }

      #photos-primary .imageContainer,
      #photos-secondary .imageContainer {
        margin: 0 auto;
      }

      .license-plate {
        display: inline-block;
        vertical-align: middle;
      }

      .input-cc {
        width: 100%;
        box-sizing: border-box !important;
      }

      .input-cc,
      .input-lp {
        display: inline-block;
        box-sizing: content-box;
        font-family: 'Kenteken';
        outline: none;
        border: none;
        overflow: visible;
        border: 1px solid black;
        text-align: left;
        vertical-align: middle;
        padding: 5px;
        border-radius: 0;
        text-transform: uppercase;
        padding-left: 28px !important;
      }

      .lp-render {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding-left: 29px;
        pointer-events: none;
        overflow: hidden;
      }

      .lp-render .input-lp-char {
        background: transparent;
        padding: 0;
        margin: 0;
        outline: none;
        border: none;
        display: inline-block;
        font-family: 'Kenteken';
        vertical-align: middle;
        text-align: center;
        border-radius: 0;
      }

      .input-lp-parent {
        position: relative;
        font-family: 'Kenteken';
        max-width: 10ch;
      }
      
      .input-cc,
      .input-lp,
      .input-lp-parent {
        max-width: 10ch;
      }
      
      .input-cc,
      .input-lp,
      .lp-render .input-lp-char,
      .input-lp-parent {
        font-size: 33px;
      }

      #verdict-form button[disabled] {
        background: #a3a3a3;
        border-color: #919191;
        color: #fff;
      }

      .form-group:not(.has-error) .show-if-has-error {
        display: none;
      }

      .map-control-count {
        display: none;
      }

      .edit-offence-code {
        color: var(--main-color);
        cursor: pointer;
      }

      .header-block {
        position: relative;
        height: 69px;
        font-size: 1.3rem;
        border-radius: 3px;
        padding: 0;
        margin-top: 15px;
        color: #fff;
        border: none;
        box-shadow: 0 2px 3px 0px #00000038;
        background: #f8af0c;
        overflow: hidden;
      }
      
      .header-block .icon-holder {
        padding: 0 23px;
        background: #00000040;
        text-shadow: 0px 0px 4px #ffffff8f;
        height: 100%;
      }
      .header-block .icon-holder i {
        line-height: 69px;
      }
      .header-block span {
        text-shadow: 2px 2px 4px #000000bf;
      }
      .header-block .btn {
        height: 100%;
        width: 100%;
        font-size: 1rem;
        word-wrap: break-word;
        word-break: break-word;
        white-space: revert;
      }

      .header-block .header-text {
        display: inline-block;
        width: calc(100% - 15px);
        font-size: 1.2rem;
        max-width: 100%;
        word-break: normal;
      }
      .header-block.header-block-queue .header-text {
        font-size: 1.5rem;
      }
      .header-block .header-text > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
      .header-block .header-lbl {
        display: block;
        font-size: 0.7rem;
        text-transform: uppercase;
      }
      .header-block .header-block-body {
        margin: 10px 0;
        align-self: center;
        text-align: center;
        overflow: hidden;
      }
      .header-block .header-block-body.has-btn {
        margin: 0;
        height: 100%;
      }

      #suspicion tr > td:first-child {
        white-space: nowrap;
      }

      .reliability-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }

      .reliability-wrapper #reliability {
        cursor: default !important;
      }

      .v-top {
        vertical-align: top;
      }
      .v-middle {
        vertical-align: middle;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="central-verification" class="flex-columns columns custom-scroll">
      <div class="loading-page-init">
        <div class="loading-circular size-xxl text-primary" style="--fa-animation-duration: 1s; --fa-thickness: 0.3rem; height: 100%;"></div>
      </div>
      <div class="cvs-top"></div>
      <div class="column col-4">
        <div class="columns">
          <!-- Header Blocks -->
          <!-- Current Channel -->
          ${'' /*
    <div id="channel-block" class="column col-6">
      <div class="header-block full-width" style="background: var(--main-color);">
        <div class="columns col-gapless full-height">
          <div class="column col-auto" atooltip="Channel" atooltip-translate="true">
            <div class="icon-holder">
              <i class="fa-regular fa-c fa-lg fa-fw"></i>
            </div>
          </div>
          <div class="column header-block-body">
            <div class="header-text">
              <span>${channelService.getActiveChannels().pop()?.Name || 'Default'}</span>
            </div>
            <span class="header-lbl">Channel</span>
          </div>
        </div>
  
      </div>
    </div>
    */}
        
          <!-- Time Left -->
          <div class="column col-12">
            <div class="header-block" style="background: #e0f9ff;">
              <div class="columns col-gapless full-height">
                <div class="column col-auto" atooltip="Time Left" atooltip-translate="true">
                  <div class="icon-holder">
                    <i class="fa-solid fa-timer fa-lg fa-fw"></i>
                  </div>
                </div>
                <div class="column">
                  <div id="meter-container" class="full-height"></div>
                </div>
              </div>
            </div>
          </div>
        
          <!-- Current Channel Queue -->
          <div class="column col-6">
            <div class="header-block header-block-queue">
              <div class="columns col-gapless full-height">
                <div class="column col-auto" atooltip="My Assigned Queue" atooltip-translate="true">
                  <div class="icon-holder">
                    <i class="fa-regular fa-calendar-lines fa-lg fa-fw"></i>
                  </div>
                </div>
                <div class="column header-block-body">
                  <div class="header-text">
                    <span class="localqueue">0</span>
                  </div>
                  <span class="header-lbl">My Assigned Queue</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Remote PDA Queue -->
          <div class="column col-6">
            <div class="header-block header-block-queue" style="background: #ff8d00;">
              <div class="columns col-gapless full-height">
                <div class="column col-auto" atooltip="Unassigned Central Verification Queue" atooltip-translate="true">
                  <div class="icon-holder">
                    <i class="fa-regular fa-calendar-lines fa-lg fa-fw"></i>
                  </div>
                </div>
                <div class="column header-block-body text-center">
                  <div class="header-text">
                    <span class="remotequeue">0</span>
                  </div>
                  <span class="header-lbl">Unassigned Queue</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Remote PDA Queue -->
          <div class="column col-6">
            <div class="header-block header-block-queue" style="background: #572dff;">
              <div class="columns col-gapless full-height">
                <div class="column col-auto" atooltip="Remote PDA Queue" atooltip-translate="true">
                  <div class="icon-holder">
                    <i class="fa-regular fa-calendar-lines fa-lg fa-fw"></i>
                  </div>
                </div>
                <div class="column header-block-body text-center">
                  <div class="header-text">
                    <span class="pdaqueue">0</span>
                  </div>
                  <span class="header-lbl">Remote PDA Queue</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Split View -->
          <div class="column col-6">
            <div class="header-block" style="background: var(--color-grey-bg);">
              <div class="columns col-gapless full-height">
                <div class="column col-auto" atooltip="Split View" atooltip-translate="true">
                  <div class="icon-holder">
                    <i class="fa-solid fa-up-right-from-square fa-lg fa-fw"></i>
                  </div>
                </div>
                <div class="column header-block-body has-btn">
                  <button id="split-view" class="btn btn-grey">
                    Split View
                  </button>
                </div>
              </div>
            </div>
          </div>



        </div>
        <div id="verdict-history" class="kpi-block kpi-collapsable kpi-closed">
          <div class="legend-label"><i class="fa-solid fa-gavel"></i> Verdicts <span>(0)</span></div>
          <div class="columns">
            <div class="column col-12">
              <div id="verdict-history-table" style="height: 220px"></div>
            </div>
          </div>
        </div>

        <div class="kpi-block kpi-collapsable">
          <div class="legend-label"><i class="fa-solid fa-binoculars"></i> Suspicion</div>
          <table id="suspicion" data-id="suspicion" class="styled-table grid-like no-shadow full-width">
            <tr><td>Channel</td><td data-prop="Channel"></td></tr>
            <tr><td>HasParkingRight</td><td data-prop="HasParkingRight"></td></tr>
            <tr><td>DetectionTime</td><td data-prop="DetectionTime"></td></tr>
            <tr class="hidden"><td>First Seen</td><td data-prop="FirstDetectionTime"></td></tr>
            <tr class="hidden"><td>Last Seen</td><td data-prop="LastDetectionTime"></td></tr>
            <tr><td>Offence Code</td><td data-prop="OffenceCode"></td></tr>
            <tr><td>Description</td><td data-prop="OffenceDescription"></td></tr>
            <tr><td>Amount</td><td data-prop="OffencePrice"></td></tr>
          </table>
        </div>

        <div id="custom-data" class="kpi-block kpi-collapsable kpi-closed hidden">
          <div class="legend-label"><i class="fa-regular fa-barcode-read"></i> Additional Data</div>
          <div class="columns">
            <div class="column col-12">
              <div id="custom-data-table" class="bryntum-no-header" style="height: 220px"></div>
            </div>
          </div>
        </div>

        <div id="parking-machines" class="kpi-block kpi-collapsable kpi-closed hidden">
          <div class="legend-label"><i class="fa-solid fa-square-parking"></i> Parking Machines <span>(0)</span></div>
          <div class="columns">
            <div class="column col-12">
              <div id="parking-machines-table" style="height: 220px"></div>
            </div>
          </div>
        </div>

        <div id="kpi-cancel" class="kpi-block kpi-collapsable hidden">
          <div class="legend-label"><i class="fa-solid fa-pen-field"></i> Notes</div>
          <table id="notes" data-id="notes" class="styled-table grid-like no-shadow full-width">
            <tr><td>CancelUser</td><td data-prop="CancelUser"></td></tr>
            <tr><td>CancelReason</td><td data-prop="CancelReason"></td></tr>
          </table>
        </div>

        <div class="kpi-block kpi-collapsable hidden">
          <div class="legend-label">Vehicle</div>
          <table id="meta" data-id="meta" class="styled-table grid-like no-shadow full-width">
            <tr><td>Color</td><td data-prop="Color"></td></tr>
            <tr><td>Brand</td><td data-prop="Brand"></td></tr>
            <tr><td>Type</td><td data-prop="Type"></td></tr>
          </table>
        </div>

      </div>

      <div class="column col-4">
        <div step="${APageSteps.LicensePlate}" class="kpi-block cvs-step kpi-closed">
          <div class="legend-label">License Plate <span class="cvs-step-instruction"></span></div>
          <form id="lp-form" autocomplete="off" class="full-width">
            <div class="columns col-gapless">
              <div class="column col-12">
                <table id="lp-table" data-id="lp-table" class="styled-table grid-like no-shadow full-width">
                  <tbody>
                    <tr>
                      <td style="border-left-color: transparent;">
                        <div class="reliability-wrapper">
                          <span>License Plate</span>
                          <span id="reliability" class="label label-rounded label-primary" atooltip="Accuracy for License Plate Recognition">100%</span>
                        </div>
                      </td>
                      <td style="border-right-color: transparent;"><span>Country Code</span></td>
                    </tr>
                    <tr>
                      <td class="v-top" style="border-left-color: transparent;">
                        <div class="lp-wrapper">
                          <div class="input-lp-parent" style="position: relative">
                            <input type="text" maxlength="9" class="input-lp" role="presentation" autocomplete="off" style="width: 100%;box-sizing: border-box;height: 100%;">
                          </div>
                          <img class="license-plate" />
                        </div>
                      </td>
                      <td class="v-top" style="border-right-color: transparent;">
                        <div class="cc-wrapper">
                          <input type="text" maxlength="4" class="input-cc" role="presentation" autocomplete="off" value="" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column col-12"></div>
            </div>
          </form>
        </div>
        
        <div step="${APageSteps.Map}" class="kpi-block cvs-step">
          <div class="legend-label">Map <span class="cvs-step-instruction"></span></div>
          
          <div id="map" style="min-height: 350px;"></div>
          <table id="address" data-id="address" class="styled-table table-compact no-shadow full-width">
            <tr><td>ClosestAddress</td><td data-prop="ClosestAddress"></td></tr>
            <tr><td>DetectionStreet</td><td data-prop="DetectionStreet"></td></tr>
            <tr><td>PostalCode</td><td data-prop="PostalCode"></td></tr>
            <tr><td>DetectionUser</td><td data-prop="DetectionUser"></td></tr>
            <tr><td>DetectionDevice</td><td data-prop="DetectionDevice"></td></tr>
          </table>
          <div class="input-group">
            <button id="prdb-recheck" class="btn btn-grey btn-sm full-width" disabled="disabled">
              <i class="fa-solid fa-rotate-right"></i>
              Recheck Rights
            </button>
            <button id="history" class="btn btn-grey btn-sm input-group-btn full-width">
              <i class="fa-solid fa-magnifying-glass"></i>
              Review all data
            </button>
          </div>
        </div>

        <div step="${APageSteps.Remarks}" class="kpi-block cvs-step">
          <div class="legend-label">Remarks <span class="cvs-step-instruction"></span></div>
          <textarea id="remarks" class="form-input" rows="3" style="min-height: 80px;"></textarea>
        </div>
        
        <div step="${APageSteps.Verdict}" class="kpi-block cvs-step mb-3">
          <div class="legend-label">Verdict</div>
          
          <form id="verdict-form" autocomplete="off" class="full-width">
            <div class="columns col-gapless mb-2">
              <div class="column"> <button disabled="disabled" stepshortcut="1" id="verdict-fine" class="btn btn-error full-width">Fine (1)</button> </div>
              <div class="column"> <button disabled="disabled" stepshortcut="2" id="verdict-followup" class="btn btn-primary full-width">Follow Up (2)</button> </div>
              <div class="column"> <button disabled="disabled" stepshortcut="3" id="verdict-exception" class="btn btn-primary full-width">Exception (3)</button> </div>
            </div>
          </form>
        </div>
      </div>
      
      <div class="column col-4">
        <div class="kpi-block kpi-photos">

          <div class="columns col-gapless">
            <div id="view-primary" class="column col-6">
              <div id="photos-primary" class="photos photos-scroll-container"></div>
            </div>
            <div id="view-secondary" class="column col-6">
              <div id="photos-secondary" class="photos photos-scroll-container"></div>
            </div>
          </div>
          
        </div>
      </div>
    
    </div>
  `);
}
