import { DisableItem, EnableItem } from "../centralverification-initializer-legacy.js";
import { AConfig } from "../classes/AConfig.js";
import { AError } from "../classes/AError.js";
import { AExclusionArray } from "../classes/AExclusionArray.js";
import { AResponse } from "../classes/AResponse.js";
import { AEngine } from "../core/AEngine.js";
import { ADropDown } from "../core/form/ADropDown.js";
import { _getEle$ } from "../utils/maps.js";
import { AInputDate, AInputDateTime, AInputTime, weeksPassed } from "../utils/tools.js";
import { ALERT_BUTTONS, ALERT_TITLES } from "./AAlertService.js";
import { AMapHelperService } from "./AMapHelperService.js";
import { APreferenceService } from "./APreferenceService.js";
export class AFilterService {
    constructor() {
        this.multiSelectCache = {};
        this.dropdowns = [];
        Object.defineProperty(globalThis, 'FilterManager', {
            get: () => this
        });
    }
    addKeyPressHandler($ele, filter) {
        $ele.keyup(filter);
        $ele.keydown(filter);
    }
    validateLabel(text) {
        return text.replace(/[^0-9a-zA-Z]+/g, '');
    }
    applyNumericExpressions() {
        let regex = /[0-9]/g;
        const $collection = $('[Numeric]');
        $collection.on('keydown', (e) => {
            if ([8, 13, 16].includes(e.keyCode))
                return true;
            if (!e.key.match(regex)) {
                return false;
            }
        });
        $collection.removeAttr('Numeric');
    }
    applyRegularExpressions() {
        let regex = /[A-Za-z0-9?%*]/g;
        const $collection = $('[LicensePlate]');
        $collection.on('keydown', (e) => {
            if ([8, 13, 16, 37, 63, 42].includes(e.keyCode))
                return true;
            if (!e.key.match(regex)) {
                return false;
            }
        });
        $collection.on('keyup', function (e) {
            $(this).val(($(this).val() || '').toString().toUpperCase());
        });
        $collection.removeAttr('LicensePlate');
    }
    createVehicleTypeOptions(selected, id, className) {
        if (selected != null)
            selected = selected.toLowerCase();
        let options = [];
        Object.keys(VehicleTypes).map((type) => {
            options.push(`<option value="${type}"${selected == type.toLowerCase() ? ' selected' : ''}>${VehicleTypes[type]}</option>`);
        });
        return `
            <select id="${id}" class="form-select ${className}">
                ${options.join('')}
            </select>`;
    }
    createParkingRightTypeOptions(selected, id, className) {
        if (selected != null)
            selected = selected.toLowerCase();
        let options = Object.keys(ParkingRightTypes).map((type) => {
            return `<option value="${type}"${selected == type.toLowerCase() ? ' selected' : ''}>${ParkingRightTypes[type]}</option>`;
        });
        return (`
            <select id="${id}" class="form-select ${className}">
                ${options.join('')}
            </select>
        `);
    }
    createLocationTypeOptions(id = "LocationType") {
        const options = Object.keys(LocationTypes).map((type) => `<option value="${type}">${LocationTypes[type]}</option>`);
        return (`<select id="${id}" class="form-select">${options.join('')}</select>`);
    }
    showFilterWarning() {
        const requirements = [
            this.isDateCorrect,
            this.areUnificationsFilled
        ];
        for (let req of requirements) {
            const passedRequirement = req.apply(this);
            if (passedRequirement == false) {
                return new Promise(() => { });
            }
        }
        return this.checkMinWeeksWarning();
    }
    isDateCorrect() {
        const $from = $('#Filters #FromDate');
        const $to = $('#Filters #ToDate');
        if ($from.length && $to.length) {
            if (($from.val() || 0) > ($to.val() || 0)) {
                $from.addClass('is-error');
                $to.addClass('is-error');
                Alerts.incorrectDate();
                return false;
            }
            else {
                $from.removeClass('is-error');
                $to.removeClass('is-error');
            }
        }
        return true;
    }
    areUnificationsFilled() {
        const $dds = $('#Filters .dropdown.dropdown-tree');
        if ($dds.length === 0)
            return true;
        const $dropdowns = $dds.toArray().map(dd => $(dd));
        for (let $dd of $dropdowns) {
            if (!this.isUnificationFilled($dd)) {
                return false;
            }
        }
        return true;
    }
    isUnificationFilled($ddw_or_$dd) {
        const $dd = $ddw_or_$dd.is('.dropdown.dropdown-tree') ? $ddw_or_$dd : $ddw_or_$dd.find('.dropdown.dropdown-tree');
        const unificationOptions = $dd.find('[unificationindex]').toArray();
        const unificationIndexes = unificationOptions.filter(c => c.checked).map(c => Number($(c).attr('unificationindex')));
        if (unificationIndexes.length === 0) {
            Alerts.emptyUnification();
            return false;
        }
        return true;
    }
    checkMinWeeksWarning() {
        const minWeeksWarning = AConfig.get('filters.minWeeksWarning', 8);
        const timeframe = `${minWeeksWarning} weeks`;
        return new Promise(async (resolve, reject) => {
            const dates = this.getDateFilters();
            // @ts-ignore
            if (dates === null)
                return resolve();
            const { FromDate, ToDate } = dates;
            const weeks = weeksPassed(FromDate, ToDate);
            if (weeks < minWeeksWarning) {
                return resolve();
            }
            try {
                const toTranslate = [
                    `You have selected a timeframe over${timeframe}`,
                    `This action may take some time to process`,
                    `Are you sure you want to continue`,
                    `Yes`,
                    `No`
                ];
                const promises = toTranslate.map(text => Translate.get(text));
                const [line1, line2, line3, yes, no] = await Loading.waitForPromises(promises);
                const html = (`${line1}<br>${line2}<br>${line3}`);
                const events = Alerts.show({
                    title: ALERT_TITLES.Warning,
                    content: html,
                    buttons: ALERT_BUTTONS.yesNo
                });
                // @ts-ignore
                events.on('option1', async () => resolve());
            }
            catch (err) {
                reject(err);
            }
        });
    }
    load() {
        const $filters = $('#Filters');
        this.initFilters();
        this.loadSettingsInto($filters);
        this.listenToChanges($filters);
    }
    initFilters() {
        this.applyRegularExpressions();
        this.applyNumericExpressions();
        this.dropdowns = [];
        $('.wrapper-dropdown:not(.tree-config)').each((i, ele) => { this.initDropDown($(ele)); });
        $('#VerificationUser').each((i, ele) => {
            // const VerificationUsers = []
            const options = VerificationUsers.map((user) => {
                return {
                    id: user,
                    text: user,
                    checked: true
                };
            }).filter(({ id }) => id !== null || id !== '');
            this.fillDropDownTemplate(ele, options);
        });
        const $verifyResult = $('#VerifyResult');
        if ($verifyResult.is('select')) {
            for (let k in VerificationResults) {
                $("#VerifyResult").append(`<option value='${k}'>${VerificationResults[k]}</option>`);
            }
        }
        else {
            const options = Object.keys(VerificationResults).map(key => {
                return {
                    id: key,
                    text: VerificationResults[key],
                    checked: true
                };
            }).filter(({ id }) => id !== null || id !== '');
            $verifyResult.each((i, ele) => {
                this.fillDropDownTemplate(ele, options);
            });
        }
        $('#DeviceName:not([load=all])').each(function () {
            for (let k in ScanDevices) {
                $(this).append(`<option value="${k}">${ScanDevices[k]}</option>`);
            }
        });
        $('#DeviceName[load=all]').each(function () {
            for (let k in AllDevices) {
                $(this).append(`<option value="${k}">${AllDevices[k]}</option>`);
            }
        });
        $('#ParkingRightType').each(function () {
            if ($(this).find('option[value="%"]').length === 0) {
                $(this).append(`<option value="%">${Translate.getCache('all')}</option>`);
            }
            for (let k in ParkingRights) {
                $(this).append(`<option value="${k}">${ParkingRights[k]}</option>`);
            }
        });
        $('#VehicleType').each(function () {
            $(this).append(`<option value="%">${Translate.getCache('all')}</option>`);
            for (let k in VehicleTypes) {
                $(this).append(`<option value="${k}">${VehicleTypes[k]}</option>`);
            }
        });
        $('#DetectionUser').each(function () {
            $(this).append(`<option value="%">${Translate.getCache('all')}</option>`);
            for (let k in DetectionUsers) {
                $(this).append(`<option value="${DetectionUsers[k]}">${DetectionUsers[k]}</option>`);
            }
        });
        const mapHelperService = AEngine.get(AMapHelperService);
        let $areas = $('#Area');
        if (AConfig.get('filters.showZonesInsteadOfAreas', false)) {
            $areas.attr("id", "Zone");
            const $zones = $areas;
            const zones = mapHelperService.getZones();
            zones.map(zone => {
                const { Id, Name } = zone;
                $zones.append(`<option data-id="${Id}" value="${Name}">${Name}</option>`);
            });
            $zones.prev().find('label').text(Translate.getCacheFast('zone'));
        }
        else {
            for (let area of mapHelperService.getAreaPairs()) {
                const { Id, Name } = area;
                $areas.append(`<option data-id="${Id}" value='${Name}'>${Name}</option>`);
            }
        }
    }
    async fillDropDownTemplate(elementOrId, options) {
        try {
            const $dropdownWrapper = (typeof elementOrId === 'string') ? $(`#${elementOrId}`) : _getEle$(elementOrId);
            const elementId = $dropdownWrapper.attr('id');
            if (elementId === undefined)
                throw new Error(`Dropdown isn't configured correctly!`);
            const $ul = $dropdownWrapper.find('.dropdown');
            $dropdownWrapper.addClass('wrapper-dropdown');
            const translations = await Loading.waitForPromises(Translate.get([
                'Select All',
                'Select None'
            ]));
            const $selectAll = $(`<li> <label class="form-checkbox">${translations['Select All']}</label> </li>`);
            const $selectNone = $(`<li> <label class="form-checkbox">${translations['Select None']}</label> </li>`);
            $ul.append($selectAll);
            $ul.append($selectNone);
            $selectAll.click(e => {
                const dropdown = $dropdownWrapper.data('DropDown');
                dropdown.selectAll();
            });
            $selectNone.click(e => {
                const dropdown = $dropdownWrapper.data('DropDown');
                dropdown.selectNone();
            });
            for (let { id, text, checked } of options) {
                id = elementId + '->' + id;
                const $ele = $(`
                    <li>
                        <label class="form-checkbox">
                            <input type="checkbox" id="${id}" key="${id}" ${checked === true ? 'checked="checked"' : ''}>
                            <i class="form-icon"></i> ${text}
                        </label>
                    </li>
                `);
                $ul.append($ele);
            }
            if ($dropdownWrapper.is('#VerifyResult')) {
                const $refreshList = $(`<li> <label class="form-checkbox">Refresh List</label> </li>`);
                $ul.append($refreshList);
                $refreshList.on('click', e => {
                    Loading.waitForPromises(requestService.query({
                        Query: (`
                SELECT VerificationResult, VerificationResultText, COUNT(*)
                FROM verifications
                WHERE VerificationEndTime BETWEEN :FromDate AND :ToDate
                GROUP BY VerificationResult, VerificationResultText;
              `),
                        Params: FilterManager.saveExplicit()
                    })).then(res => new AResponse(res)).then(ares => {
                        const $dd = $dropdownWrapper.find('.dropdown');
                        $dd.find('li').each((i, ele) => {
                            if ($(ele).find('input').length) {
                                $(ele).addClass('li-grey');
                            }
                        });
                        ares.loop((row) => {
                            const { VerificationResult, VerificationResultText } = row;
                            const $checkbox = $dd.find(`li [type="checkbox"][key="VerifyResult->${VerificationResult}"]`);
                            if ($checkbox.length) {
                                $checkbox.closest('.li-grey').removeClass('li-grey');
                            }
                        });
                    }).catch(AError.handle);
                });
            }
            this.initDropDown($dropdownWrapper);
        }
        catch (err) {
            console.warn('context', elementOrId, options);
            AError.handle(err);
        }
    }
    /**
     * @param {any} $dropdown
     * @param {any} [options]
     * @returns {ADropDown[]}
     */
    initDropDown($dropdown, options) {
        if (options === undefined) {
            options = this.multiSelectCache || {};
        }
        const dropdown = new ADropDown($dropdown).restoreState(options);
        this.dropdowns.push(dropdown);
        return this.dropdowns;
    }
    setDefaultLimit() {
        if (window.location.href.includes('/reports/')) {
            FilterSettings.Limit = AConfig.get('filters.tables.maxResults');
        }
        else if (window.location.href.includes('/maps/')) {
            FilterSettings.Limit = AConfig.get('filters.maps.maxResults');
        }
        else {
            FilterSettings.Limit = AConfig.get('filters.default.maxResults');
        }
    }
    setDefaultDate() {
        if (AConfig.get('filters.overrideFilters', false)) {
            const { fromDate, fromTime, toDate, toTime } = AConfig.get('filters.override', {});
            FilterSettings.FromDate = new Date(`${fromDate} ${fromTime}`).toJSON();
            FilterSettings.ToDate = new Date(`${toDate} ${toTime}`).toJSON();
        }
        else {
            const now = new Date();
            const today = new Date((now.getTimezoneOffset() * 60 * 1000) + now.getTime() - (now.getTime() % (24 * 60 * 60 * 1000)));
            const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
            FilterSettings.FromDate = today.toJSON();
            FilterSettings.ToDate = tomorrow.toJSON();
        }
    }
    loadSettingsInto($filters, reset = false) {
        if (!FilterSettings)
            throw new Error(`FilterSettings is missing!`);
        try {
            if (!FilterSettings.Limit) {
                this.setDefaultLimit();
            }
            if (FilterSettings.FromDate == null || FilterSettings.ToDate == null) {
                this.setDefaultDate();
            }
            const excludedInputs = ['FromDate', 'ToDate'];
            // Set filter inputs
            Object.keys(FilterSettings).map((key) => {
                if (!excludedInputs.includes(key)) {
                    let $input = $filters.find(key);
                    if ($input.length === 0 && key.length > 0) {
                        $input = $filters.find(`#${key}`);
                    }
                    $input.val(FilterSettings[key]);
                }
            });
            // Set filter select inputs
            $filters.find('select').each((i, ele) => {
                const $select = $(ele);
                const id = $select.attr('id');
                if (FilterSettings.hasOwnProperty(id)) {
                    const $selection = $select.find(`option[value="${FilterSettings[id]}"]`);
                    if ($selection.length) {
                        $select.val(FilterSettings[id]);
                    }
                    else {
                        $select.val($select.find('option').first().val() || '');
                    }
                }
            });
            $filters.find('#FromDate').val(AInputDate(new Date(FilterSettings.FromDate)));
            $filters.find('#FromTime').val(AInputTime(new Date(FilterSettings.FromDate)));
            $filters.find('#ToDate').val(AInputDate(new Date(FilterSettings.ToDate)));
            $filters.find('#ToTime').val(AInputTime(new Date(FilterSettings.ToDate)));
            if (reset === true) {
                this.reset();
            }
        }
        catch (err) {
            return AError.handle(err);
        }
    }
    isElementAllowedToEnable({ $filters, $watchEle, ele }) {
        if (!$watchEle && ele) {
            const id = $(ele).attr('enabled-if');
            $watchEle = $filters.find(`#${id}`);
        }
        if ($watchEle.is('[type="checkbox"]')) {
            const isChecked = $watchEle.prop('checked');
            const isDisabled = $watchEle.is('[disabled]');
            return (isChecked && !isDisabled);
        }
        else {
            const val = $watchEle.val();
            const hasValue = val && val.toString().length && val !== '%';
            const isDisabled = $watchEle.is('[disabled]');
            return (hasValue && !isDisabled);
        }
    }
    listenToChanges($filters) {
        $filters.find('[type="checkbox"]').change(function () {
            // @ts-ignore
            const $checkbox = $(this);
            const $listener = $filters.find(`[enabled-if="${$checkbox.attr('id')}"]`);
            if ($listener.length === 0) {
                return;
            }
            // @ts-ignore
            $listener.prop('disabled', !this.checked);
        });
        $filters.find('select').change(function () {
            // @ts-ignore
            const $select = $(this);
            const $listener = $filters.find(`[enabled-if="${$select.attr('id')}"]`);
            if ($listener.length === 0) {
                return;
            }
            if ($listener.is('[type="checkbox"]')) {
                const val = $select.val();
                const hasValue = val && val.toString().length && val !== '%';
                if (!hasValue) {
                    $listener.prop('checked', false);
                }
            }
            else {
                console.error(`$listener for [type="${$listener.attr('type')}"] Not Implemented Yet!`);
            }
        });
        $filters.find('[enabled-if]').each((_, ele) => {
            const $watchEle = $filters.find(`#${$(ele).attr('enabled-if')}`);
            $(ele).prop('disabled', !this.isElementAllowedToEnable({ $filters, $watchEle }));
            $watchEle.on('FilterManager.change change', () => {
                const isAllowed = this.isElementAllowedToEnable({ $filters, $watchEle });
                $(ele).prop('disabled', !isAllowed);
            });
        });
    }
    get betweenLastWeekAndNow() {
        return { FromDate: AInputDateTime(this.weekAgoDate), ToDate: AInputDateTime(new Date()) };
    }
    get betweenLastMonthAndNowDay() {
        return {
            FromDate: AInputDateTime(moment(this.monthAgoDate).startOf('day').toDate()),
            ToDate: AInputDateTime(moment(new Date()).add(1, 'day').startOf('day').toDate())
        };
    }
    get betweenLastMonthAndNow() {
        return { FromDate: AInputDateTime(this.monthAgoDate), ToDate: AInputDateTime(new Date()) };
    }
    get betweenLastHalfYearAndNow() {
        return { FromDate: AInputDateTime(this.sixMonthsAgoDate), ToDate: AInputDateTime(new Date()) };
    }
    get betweenLastHalfYearAndNowExclTime() {
        const sixMonthsAgoDate = moment(new Date()).subtract(6, 'months').startOf('day').toDate();
        const nowDate = moment(new Date()).startOf('day').toDate();
        return {
            FromDate: AInputDate(sixMonthsAgoDate),
            ToDate: AInputDateTime(nowDate)
        };
    }
    /**
     * @returns {{ FromDate: string, ToDate: string }}
     */
    get betweenYesterdayAndNow() {
        const { yesterdayDate } = FilterManager;
        return { FromDate: AInputDateTime(yesterdayDate), ToDate: AInputDateTime(new Date()) };
    }
    /**
     * @returns {{ FromDate: string, ToDate: string }}
     */
    get betweenYesterdayAndToday() {
        const { yesterdayDate, todayDate } = FilterManager;
        return { FromDate: AInputDateTime(yesterdayDate), ToDate: AInputDateTime(todayDate) };
    }
    /**
     * @returns {{ FromDate: string, ToDate: string }}
     */
    get betweenTodayAndTomorrow() {
        const { todayDate, tomorrowDate } = FilterManager;
        return { FromDate: AInputDateTime(todayDate), ToDate: AInputDateTime(tomorrowDate) };
    }
    get todayDate() {
        const now = new Date();
        return new Date((now.getTimezoneOffset() * 60 * 1000) + now.getTime() - (now.getTime() % (24 * 60 * 60 * 1000)));
    }
    get tomorrowDate() {
        return new Date(this.todayDate.getTime() + this.ONE_DAY);
    }
    get yesterdayDate() {
        return new Date(this.todayDate.getTime() - this.ONE_DAY);
    }
    get weekAgoDate() {
        // @ts-ignore
        return moment(new Date()).subtract(1, 'weeks').toDate();
    }
    get monthAgoDate() {
        // @ts-ignore
        return moment(new Date()).subtract(1, 'months').toDate();
    }
    get sixMonthsAgoDate() {
        // @ts-ignore
        return moment(new Date()).subtract(6, 'months').toDate();
    }
    get startOfWeek() {
        // @ts-ignore
        return moment(new Date()).startOf('isoWeek').toDate();
    }
    get endOfWeek() {
        // @ts-ignore
        return moment(new Date()).endOf('isoWeek').toDate();
    }
    get startOfMonth() {
        // @ts-ignore
        return moment(new Date()).startOf('month').toDate();
    }
    get endOfMonth() {
        // @ts-ignore
        return moment(new Date()).endOf('month').toDate();
    }
    get startOfPrevMonth() {
        return moment(new Date()).subtract(1, 'months').startOf('month').toDate();
    }
    get endOfPrevMonth() {
        return moment(new Date()).subtract(1, 'months').endOf('month').toDate();
    }
    get ONE_DAY() {
        return (24 * 60 * 60 * 1000);
    }
    get today() {
        return AInputDate(new Date(this.todayDate));
    }
    get yesterday() {
        const yesterday = new Date(this.todayDate.getTime() - this.ONE_DAY);
        return AInputDate(new Date(yesterday));
    }
    get tomorrow() {
        const tomorrow = new Date(this.todayDate.getTime() + this.ONE_DAY);
        return AInputDate(new Date(tomorrow));
    }
    get unixYesterday() {
        return new Date(this.todayDate.getTime() - (24 * 60 * 60 * 1000));
    }
    get unixYesterday1() {
        return new Date(this.todayDate.getTime() - (24 * 60 * 60 * 1000)).getTime();
    }
    get unixToday() {
        return Date.now();
    }
    get unixTomorrow() {
        return new Date(this.todayDate.getTime() + (24 * 60 * 60 * 1000));
    }
    get unixTomorrow1() {
        return new Date(this.todayDate.getTime() + (24 * 60 * 60 * 1000)).getTime();
    }
    get unixMonthAgo() {
        const lastMonth = this.todayDate;
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return (lastMonth);
    }
    subtractMonth(date) {
        return moment(date).subtract(1, 'months').toDate();
    }
    getDateFilters() {
        const $FromDate = $('#FromDate'), $FromTime = $('#FromTime'), $ToDate = $('#ToDate'), $ToTime = $('#ToTime');
        const hasAllInputs = (inputs) => { for (let $input of inputs) {
            if ($input.length === 0)
                return false;
        } return true; };
        if (hasAllInputs([$FromDate, $FromTime, $ToDate, $ToTime])) {
            const FromDate = new Date($FromDate.val() + ' ' + $FromTime.val());
            const ToDate = new Date($ToDate.val() + ' ' + $ToTime.val());
            return { FromDate, ToDate };
        }
        else {
            return null;
        }
    }
    save(options) {
        const { cacheFilters, maxLimit } = Object.assign({}, { cacheFilters: true, maxLimit: AConfig.get('filters.maxResultsCeiling', 100000) }, options);
        const output = {};
        if ($('#FromDate').length && $('#ToDate').length) {
            const dates = this.getDateFilters();
            if (dates) {
                const FromDate = dates.FromDate.toJSON();
                const ToDate = dates.ToDate.toJSON();
                Object.assign(output, { FromDate, ToDate });
                if (cacheFilters) {
                    Object.assign(FilterSettings, { FromDate, ToDate });
                }
            }
        }
        $('#Filters :input').each((i, ele) => {
            const $ele = $(ele);
            const id = $ele.attr('id');
            let value = $ele.is(':checkbox') ? $ele.prop('checked') : $ele.val(); // $ele.val()
            if (id === 'Limit' && Number(value) > maxLimit) {
                value = maxLimit;
            }
            const hasDropdown = $ele.parents('.dropdown').length > 0;
            if (!hasDropdown && !['FromDate', 'FromTime', 'ToDate', 'ToTime', 'RefreshButton'].includes(id)) {
                // this.log(id + ' ' + value)
                output[id] = value;
                if (cacheFilters) {
                    FilterSettings[id] = value;
                }
            }
        });
        $('.wrapper-dropdown:not(.tree-config)').each((i, ele) => {
            const $ele = $(ele);
            const id = $ele.attr('id');
            const { selectedTextsQuery } = $ele.data('DropDown');
            output[id] = selectedTextsQuery;
            if (cacheFilters) {
                FilterSettings[id] = selectedTextsQuery;
            }
        });
        $('.wrapper-dropdown:not(.tree-config) :input').each((i, ele) => {
            const $ele = $(ele);
            const id = $ele.attr('id');
            const value = $ele.is(':checkbox') ? $ele.prop('checked') : undefined;
            if (id && value !== undefined) {
                this.multiSelectCache[id] = value;
            }
        });
        $('.wrapper-dropdown.tree-config').each((_, ddw) => {
            const $ddw = $(ddw), $dd = $(ddw).find('.dropdown');
            const unificationOptions = $dd.find('[unificationindex]').toArray();
            // @ts-ignore
            const unificationIndexes = unificationOptions.filter(c => c.checked).map(c => Number($(c).attr('unificationindex')));
            const id = $ddw.attr('id');
            const value = unificationIndexes;
            // TODO: Fix null values in optimization
            // const value = (unificationOptions.length === unificationIndexes.length) ? '%' : unificationIndexes
            output[id] = value;
            if (cacheFilters) {
                FilterSettings[id] = value;
            }
        });
        return output;
    }
    /**
     * Created "IN (..., ..., ...) Statement"
     * Example: WHERE username IN ('ivan', 'jaap', 'wil')
     * Exclusion example: WHERE username NOT IN ('ivan', 'jaap', 'wil')
     * @param {*} array array of values to look for (or not to look for)
     */
    buildQueryFindInArray(array) {
        if (array instanceof AExclusionArray) {
            return (`NOT IN ('${array.join(`','`)}')`);
        }
        if (array instanceof Array) {
            return (`IN ('${array.join(`','`)}')`);
        }
        throw new Error(`array is not an instance of Array or AExclusionArray`);
    }
    /**
     * Only return the filled in values.
     * So the '%' values are excluded
     */
    saveExplicit(options) {
        const filters = this.save(options);
        Object.keys(filters).filter(key => filters[key] === '%' || filters[key].length === 0).map(key => {
            delete filters[key];
        });
        return filters;
    }
    /**
     * Transforms array of conditions to where clause seperated with 'AND' operator
     * @param {*} conditions
     */
    toWhere(conditions) {
        if (conditions.length === 0) {
            return '1=1';
        }
        return conditions.join(' AND ');
    }
    /**
     * Extract filters like ['FromDate', 'ToDate', 'Images', 'Limit]
     * @param {*} filters
     * @param {*} keys
     */
    extractFromFilters(filters, keys) {
        const extracted = {};
        keys.map(key => {
            if (filters.hasOwnProperty(key)) {
                extracted[key] = filters[key];
            }
        });
        return extracted;
    }
    /**
     * Generate SQL where clause conditions for query filters
     * EXAMPLE:
     * ['ParkingRightType = :ParkingRightType', 'DetectionDevice = :DeviceName', 'VerificationResult = :VerifyResult']
     * @param {*} filters filters created by FilterManager.save() or FilterManager.saveExplicit()
     */
    generateConditionsSQL(filters) {
        const output = [];
        const specialConditions = this.extractFromFilters(filters, ['FromDate', 'ToDate', 'Images', 'Limit']);
        const { FromDate, ToDate } = specialConditions;
        if (FromDate && ToDate) {
            output.push(`DetectionTime BETWEEN :FromDate AND :ToDate`);
        }
        Object.keys(filters).map(key => {
            // Skip conditions that are special like ['FromDate', 'ToDate', 'Images', 'Limit']
            if (!specialConditions.hasOwnProperty(key)) {
                output.push(`${key} = :${key}`);
            }
        });
        return output;
    }
    /**
     *
     * @param {*} filters
     */
    generateWhereSQL(filters) {
        const conditions = this.generateConditionsSQL(filters);
        if (conditions.length > 0) {
            return `WHERE ${conditions.join(' AND ')}`;
        }
        return '';
    }
    createToggle($toggleShortcut, $buttons, isCollapsed = null) {
        const preferenceService = AEngine.get(APreferenceService);
        const toggle = (hide) => {
            $toggleShortcut.find('i').toggleClass('fa-flip-vertical', hide);
            $buttons.toggleClass('hidden', hide);
        };
        $toggleShortcut.click(e => {
            const isHidden = $buttons.hasClass('hidden');
            toggle(!isHidden);
            preferenceService.save('filters-shortcut-collapsed', isHidden);
        });
        toggle(!isCollapsed);
    }
    selectShortcut(classIdentifier) {
        this.removeSelectedShortcutHighlight();
        this.selectedShortcut = classIdentifier;
        this.highlightSelectedShortcut();
    }
    unselectShortcut() {
        this.removeSelectedShortcutHighlight();
        this.selectedShortcut = null;
    }
    highlightSelectedShortcut() {
        if (this.selectedShortcut) {
            $('#Filters').find(this.selectedShortcut).addClass('active');
        }
    }
    removeSelectedShortcutHighlight() {
        $('#Filters').find(this.selectedShortcut).removeClass('active');
    }
    /**
     * Set Filter Inputs Active or Inactive
     * @param {boolean} active
     * @param {{silent: boolean}} [options]
     */
    setActive(active, options = { silent: false }) {
        const $filters = $('#Filters');
        const $input = $filters.find(':input:not([enabled-if])');
        const $allInputs = $filters.find(':input');
        if (active === true) {
            $filters.removeClass('disabled');
            EnableItem($input, options);
        }
        else {
            $filters.addClass('disabled');
            DisableItem($input, options);
        }
        $allInputs.trigger('FilterManager.change', [options]);
    }
    reset() {
        if (!FilterSettings)
            throw new Error(`Coudln't find FilterSettings!`);
        for (let key in FilterSettings) {
            delete FilterSettings[key];
        }
    }
}
